import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { NavLink, Route, Switch } from 'react-router-dom'
import { PropagateLoader } from 'react-spinners'
import Avatar from 'client/components/Avatar'
import { getUserProfile } from '../../modules/profiles'
import { getClub, applyToClub } from '../../modules/clubs'
import { IClub, IUser, IUserProfile } from 'client/types'
import styles from './styles.module.scss'
import { Button, Header, Icon, Modal, Input, Tab } from 'semantic-ui-react'
// import Chat from './Chat'
import Members from './Members'

interface Props {
  currentProfile: IUserProfile,
  currentClub: IClub
  selectedClub: number,
  user: IUser,
  getClub: (clubId: IClub['_id']) => any,
  applyToClub: (clubId: IClub['_id'], name: string, email: string) => any,
  getUserProfile: (userId: IUser['_id']) => any,
}

const Club: React.FC<Props> = (props: Props) => {
  if (!props.user) {
    console.log('! props.user')
    return(
      <div className='loading-container'>
          <PropagateLoader
            loading={!props.user}
          />
      </div>
    )
  }

  console.log('club props:', props)

  useEffect(() => {
    console.log('in useEff')
    if (props.user && props.user._id) {
      props.getUserProfile(props.user._id)
    }
  }, [props.user])

  const [openReferModal, setOpenReferModal] = React.useState(false)

  const isLoading: boolean = props.currentProfile === undefined
  const [didConfirm, setDidConfirm] = useState(false)
  const [refereeName, setRefereeName] = useState("")
  const [refereeEmail, setRefereeEmail] = useState("")
  const [loading, setLoading] = useState(false)

  const user = props.user
  const selectedClub = (props.selectedClub ? props.selectedClub : 0);
  const clubId = (user ? user.clubs[selectedClub] : undefined);

  const referModal = () => {
    return (
      <Modal
        basic
        onClose={() => {
            setOpenReferModal(false)
        }}
        onOpen={() => {
          setDidConfirm(false)
          setOpenReferModal(true)
        }}
        open={openReferModal}
        size='small'
        trigger={<Button color="blue">Refer Friends</Button>}
      >
        <Header icon>
          <Icon name='add user' />
          Refer to Group
        </Header>
        <Modal.Content style={{display: 'flex', justifyContent: 'center'}}>
          {
            (!didConfirm) ? (
              <div>
                <Input 
                    type='text'
                    placeholder="Name"
                    onChange={(event) => setRefereeName(event.target.value)}
                />
                <Input 
                    type='text'
                    placeholder="Email"
                    onChange={(event) => setRefereeEmail(event.target.value)}
                /> 
              </div>
            ) : (
              <span>{refereeName} has been referred to the group. They will get an email invite when an Admin approves their application.</span>
            )
          }
        </Modal.Content>
        <Modal.Actions style={{display: 'flex', justifyContent: 'center'}}>
          <Button basic color='red' inverted onClick={() => {
            setOpenReferModal(false)
          }}>
            <Icon name='times' /> {didConfirm ? 'Close' : 'Cancel'}
          </Button>
          <Button
            color='green'
            inverted
            disabled={loading}
            onClick={ () => {
              if (didConfirm) {
                // history.push('/admin')
                setOpenReferModal(false)
              } else {
                props.applyToClub(clubId, refereeName, refereeEmail)
                setDidConfirm(true)
              }
            }
          } >
            {!didConfirm &&
              <Icon name='add' />
            }
            {didConfirm ? 'Go to Club' : 'Confirm'}
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

  const clubPanes = [
    // {
    //   menuItem: {
    //     as: NavLink,
    //     id: 'clubTab1',
    //     content: 'Group Chat',
    //     to: `/club`,
    //     exact: true,
    //     key: 'groupChat'
    //   },
    //   pane: (
    //     <Route
    //       path='/club'
    //       exact
    //       render={() => (
    //         <Tab.Pane attached={false}>
    //           <Chat chatGroupId={props.currentClub.chatGroup}/>
    //         </Tab.Pane>
    //       )}
    //     />
    //   )
    // },
    {
      menuItem: {
        as: NavLink,
        id: 'clubTab2',
        content: 'Members',
        to: `/club/members`,
        exact: true,
        key: 'members'
      },
      pane: (
        <Route
          path='/club/members'
          exact
          render={() => (
            <Tab.Pane attached={false}>
              <Members />
            </Tab.Pane>
          )}
        />
      )
    }
  ]

  return (
    <div className={styles.activityContainer}>
      {isLoading && (
        <div className='loading-container'>
          <PropagateLoader
            loading={isLoading}
          />
        </div>
      )}
      {!isLoading && (
        <div className={styles.profile}>
          <div className={styles.profile__head}>
            <Avatar
              user={props.currentProfile}
            />
            <div className={styles.profile__title}>
              {props.currentProfile.firstName} {props.currentProfile.lastName}
            </div>
            <div className={styles.profile__subtitle}>
              {props.currentProfile.paymentMethod}: {props.currentProfile.paymentHandle}
            </div>
            <div className={styles.profile__subtitle}>
              {referModal()}
            </div>
          </div>
          <div className={styles.profile__body}>
            <Members />
            {/* <Switch>
              <Tab
                menu={{ secondary: true, pointing: true }}
                panes={clubPanes}
                onTabChange={() => {}}
                activeIndex={0}
                renderActiveOnly={false}
              />
            </Switch> */}
          </div>
        </div>
      )}
    </div>
  )
}
const mapStateToProps = ({ profiles, auth, chat, club }) => ({
  currentProfile: profiles.currentProfile,
  user: auth.user,
  currentClub: club.currentClub,
  clubChat: chat.clubChatId
})
const mapDispatchToProps = {
  getUserProfile,
  getClub,
  applyToClub
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Club)
