import React from 'react'
import styles from './styles.module.scss'

const Community: React.FC<{}> = () => {
  return (
    <div className="container">
      <h2 style={{ textAlign: 'center', marginBottom: '30px' }}>A Community Based on Trust</h2>
      <p>For those of you who are skeptical of getting paid out, we understand. In the future, we plan on accepting deposits and holding the money for a wager in escrow so that we can truly guarantee payouts. Until then, however, this is a community based on trust. We believe that the following measures will allow Wishbone to function smoothly:</p>
      <h4>Exclusivity: new members of Wishbone must be referred by existing users</h4>
      <p className={styles.small}>We are willing to sacrifice growth of this platform in order to ensure the best possible user experience. Therefore, in order to add new members to the site, users must request a one-time referral code from Wishbone. You vouch for anyone you refer. This means that if a user you added welches and is subsequently removed from the site, you're on the hook.</p>
      <h4>Transparency: User Profiles {'&'} Messaging</h4>
      <p className={styles.small}>While this is a community of "strangers", it is nice to have information about the folks you'll be betting with. Visiting another user's profile will allow you to see some basic information: How long they’ve been a member, how many bets they've settled, as well as who added them to the community. We also plan on adding a messaging feature so that users can communicate with each other.</p>
      <h4>Welch Reporting: Users can report their opponents if payment is not received within a reasonable timeframe</h4>
      <p className={styles.small}>If you don't receive payment within 24 hours, press the "Friendly Reminder" button to nudge your opponent. After 72 hours without receiving payment, you may file a claim with our support team and we'll get to the bottom of it. Non-compliance with our guidelines will result in permanent removal from the site.</p>
      <h4>Support</h4>
      <p className={styles.small}>While we do not anticipate many issues between users, our support team is available to handle disputes on a case-by-case basis.</p>
    </div>
  )
}

export default Community
