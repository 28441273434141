import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { PropagateLoader } from 'react-spinners'
import { getAdminClub } from '../../modules/admin'
import { IClub, IUser } from 'client/types'
import styles from './styles.module.scss'
import ApplicationItem from './Application'

interface Props {
  user: IUser,
  currentClub: IClub,
  getApplications: (clubName: string) => any,
}

const AdminDashboard: React.FC<Props> = (props: Props) => {
  console.log('admin props:', props)
  const isLoading: boolean = (props.currentClub ? props.currentClub.applications === undefined : true)

  if (isLoading) {
    return(
      <div className='loading-container'>
        <PropagateLoader
          loading={isLoading}
        />
      </div>
    )
  }

  const currentClub = props.currentClub
  const applications = props.currentClub.applications
  const members = props.currentClub.users

  return (
    <div className={styles.activityContainer}>
      {!isLoading && (
        <div className={styles.container}>
          <h2>Admin Page - {currentClub?.name}</h2>

          <h4>Admins</h4>
          <div className={styles.adminsList}>
            {currentClub && currentClub.admins.map((item: any, index) => {
              return (
                <div key={index} style={{display: 'flex'}}>
                  <div style={{paddingRight: '2px'}}>{item.firstName}</div>
                  <div style={{paddingRight: '5px'}}>{item.lastName}</div>
                  <div>{item.email}</div>
                </div>
              )
            })}
          </div>

          <h4>Members</h4>
          <div className={styles.membersList}>
            <div className={styles.header}>
              <div>Name</div>
              <div>Email</div>
              {/* <div>Referrer</div>
              <div>Status</div> */}
            </div>
            <div className={styles.tableBody}>
              {members.length && members.map((member: any, index) => {
                  return (
                    <div key={index} style={{display: 'flex'}}>
                      <div style={{paddingRight: '2px'}}>{member.firstName}</div>
                      <div style={{paddingRight: '5px'}}>{member.lastName}</div>
                      <div>{member.email}</div>
                    </div>
                  )
                })
              }
              {/* {applications.length
                ? applications.map((application, index) => <ApplicationItem key={index} application={application} />)
                : <p>No Applications to Show</p>
              } */}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
const mapStateToProps = ({ club }) => ({
  currentClub: club.currentClub
})
const mapDispatchToProps = {
  getApplications: getAdminClub
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDashboard)