import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { IClub, IUser } from 'client/types'
// import {getChatGroups} from '../../modules/chat'

interface Props {
  user: IUser,
  isAdmin: boolean,
  club: IClub,
  // getChatGroups: () => void
}

const Navigation: React.FC<Props> = (props: Props) => {
  return (
    <div className={'navbar nav'}>
      <div className='navbar__content'>
        <NavLink activeClassName='navbar__link--active' to='/' exact className='navbar__link'>Offers</NavLink>
        <NavLink activeClassName='navbar__link--active' to='/post' className='navbar__link'>Post a Bet</NavLink>
        <NavLink activeClassName='navbar__link--active' to={'/my-bets'} className='navbar__link'>My Bets</NavLink>
        <NavLink activeClassName='navbar__link--active' to={'/club'} className='navbar__link'>{(props.club ? props.club.name : "Club")}</NavLink>
        {props.isAdmin &&
          <NavLink activeClassName='navbar__link--active' to={'/admin'} className='navbar__link'>Admin</NavLink>
        }
      </div>
    </div >
  )
}

const mapStateToProps = ({ auth, club }) => ({
  user: auth.user,
  isAdmin: auth.isAdmin,
  club: club.currentClub
})
const mapDispatchToProps = {
  // getChatGroups
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navigation)
