import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { PropagateLoader } from 'react-spinners'
import {  } from '../../../modules/auth'
import { IApplication, IClub, IUser } from 'client/types'
import styles from './styles.module.scss'
import { getUserProfile } from 'client/modules/profiles'
import Member from './Member'

interface Props {
  currentClub: IClub,
}

const Members: React.FC<Props> = (props: Props) => {
  const isLoading: boolean = (props.currentClub ? props.currentClub.applications === undefined : true)
  if (isLoading) {
    return (
      <div className={styles.activityContainer}>
      <div className='loading-container'>
            <PropagateLoader
              loading={isLoading}
            />
          </div>
      </div>
    )
  }
  const currentClub = props.currentClub
  const applications = currentClub.applications
  const admins = currentClub.admins
  const members = currentClub.users

  console.log('rendering Members:', props)
  return (
    <div className={styles.activityContainer}>
      {isLoading && (
        <div className='loading-container'>
          <PropagateLoader
            loading={isLoading}
          />
        </div>
      )}
      {!isLoading && (
        <div className={styles.container}>
          <h4>Members</h4>
          <div className={styles.membersList}>
            <div className={styles.header}>
              <div>Name</div>
              <div>Email</div>
            </div>
            {/* <div className={styles.tableBody}> */}
            {members.length && members.map((member: any, index) => {
              return (
                <div key={index} className={styles.gridded}>
                  <div style={{paddingRight: '2px'}}>{member.firstName} {member.lastName}</div>
                  <div>{member.email}</div>
                </div>
              )
            })}
            {/* </div> */}
          </div>
        </div>
      )}
    </div>
  )
}
const mapStateToProps = ({ club }) => ({
  currentClub: club.currentClub
})
export default connect(
  mapStateToProps
)(Members)