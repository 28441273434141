// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".smallList__wS0bh li,.smallList__wS0bh ol{font-size:14px}.small_23H_AQ{font-size:14px}.small_23H_AQ a{font-size:14px}img.fitImage_1mqWIT{width:100%;height:auto;margin-bottom:15px}\n", ""]);
// Exports
exports.locals = {
	"smallList": "smallList__wS0bh",
	"small": "small_23H_AQ",
	"fitImage": "fitImage_1mqWIT"
};
module.exports = exports;
