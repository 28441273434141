import axios from 'axios'
import { find } from 'lodash'
import { Dispatch, Action } from 'redux'
import { BetStatus, IBet, IBetOption, IClub, IBetSlipItem, BetSide, BetType } from 'client/types'
import { API_URL } from 'client/utils/api'

const DEFAULT_BET_AMOUNT = 20

// Types
export enum BetTypes {
  BET_ERROR = 'bet/BET_ERROR',
  CURRENT_BET_DETAIL = 'bet/CURRENT_BET_DETAIL',
  SET_OPEN_BETS = 'bet/SET_OPEN_BETS',
  SET_ACCEPTED_BETS = 'bet/SET_ACCEPTED_BETS',
  SET_SETTLED_BETS = 'bet/SET_SETTLED_BETS',
  SET_EXPIRED_BETS = 'bet/SET_EXPIRED_BETS',
  ADD_TO_BETSLIP = 'bet/ADD_TO_BETSLIP',
  SET_BETSLIP_AMOUNT = 'bet/SET_BETSLIP_AMOUNT',
  REMOVE_FROM_BETSLIP = 'bet/REMOVE_FROM_BETSLIP',
  CLEAR_BETSLIP = 'bet/CLEAR_BETSLIP',
  TOGGLE_SHOW_BETSLIP = 'bet/TOGGLE_SHOW_BETSLIP',
  CREATE_OPEN_BET = 'bet/CREATE_OPEN_BET',
  ACCEPT_OPEN_BET = 'bet/ACCEPT_OPEN_BET',
  SET_JUICE_SAVED = 'bet/SET_JUICE_SAVED',
  SET_IS_SINGLE_MODE = 'bet/SET_IS_SINGLE_MODE'
}

interface BetAction extends Action {
  payload?: any
}

interface BetError {
  data: any
  status: number
  response: any
}

interface BetState {
  error?: BetError
  currentBetDetail?: IBet,
  openBets?: Array<IBet>,
  acceptedBets?: Array<IBet>,
  settledBets?: Array<IBet>,
  expiredBets?: Array<IBet>,
  betSlip?: Array<IBetSlipItem>,
  showBetslip?: boolean,
  juiceSaved?: number,
  isSingleMode: boolean
}

// Reducer
const INITIAL_STATE: BetState = {
  error: undefined,
  currentBetDetail: undefined,
  openBets: undefined,
  acceptedBets: undefined,
  settledBets: undefined,
  expiredBets: undefined,
  betSlip: [],
  showBetslip: false,
  juiceSaved: undefined,
  isSingleMode: true
}

export default function (state = INITIAL_STATE, action: BetAction) {
  switch (action.type) {
    case BetTypes.BET_ERROR:
      return { ...state, error: action.payload }
    case BetTypes.SET_OPEN_BETS:
      return {
        ...state,
        openBets: action.payload
      }
    case BetTypes.SET_ACCEPTED_BETS:
      return {
        ...state,
        acceptedBets: action.payload
      }
    case BetTypes.SET_SETTLED_BETS:
      return {
        ...state,
        settledBets: action.payload
      }
    case BetTypes.SET_EXPIRED_BETS:
      return {
        ...state,
        expiredBets: action.payload
      }
    case BetTypes.ADD_TO_BETSLIP:
      if (state.betSlip === undefined) {
        return {
          ...state,
          betSlip: [{ fixtureId: action.payload.fixtureId, specificBetId: action.payload.specificBetId, amount: action.payload.amount }]
        }
      } 
      return {
        ...state,
        betSlip: [...state.betSlip, { fixtureId: action.payload.fixtureId, specificBetId: action.payload.specificBetId, amount: action.payload.amount }]
      }

    case BetTypes.SET_BETSLIP_AMOUNT:
      const updatedBetSlip = state.betSlip.map(item => {
        if (item.specificBetId !== action.payload.betSlipItem.specificBetId) {
          return item
        }
        
        return { ...item, amount: action.payload.amount }
      })
      return {
        ...state,
        betSlip: updatedBetSlip
      }
      
    case BetTypes.REMOVE_FROM_BETSLIP:
      const newBetSlip = [...state.betSlip.filter(item => (item.fixtureId !== action.payload.fixtureId || item.specificBetId !== action.payload.specificBetId))]
      return {
        ...state,
        betSlip: newBetSlip
      }
    case BetTypes.CLEAR_BETSLIP:
      return {
        ...state,
        betSlip: []
      }
    case BetTypes.TOGGLE_SHOW_BETSLIP:
      return {
        ...state,
        showBetslip: !state.showBetslip
      }
    case BetTypes.CREATE_OPEN_BET:
      let newOpenBetsState = []
      state.openBets === undefined
        ? newOpenBetsState = [action.payload]
        : newOpenBetsState = [...state.openBets, action.payload]
      return {
        ...state,
        openBets: newOpenBetsState
      }
    case BetTypes.CURRENT_BET_DETAIL:
      return { ...state, currentBetDetail: action.payload }
    case BetTypes.ACCEPT_OPEN_BET:
      if (state.acceptedBets === undefined) {
        return { ...state, acceptedBets: [action.payload] }
      }
      return { ...state, acceptedBets: [...state.acceptedBets, action.payload] }
    case BetTypes.SET_JUICE_SAVED:
      return {
        ...state,
        juiceSaved: action.payload
      }
    case BetTypes.SET_IS_SINGLE_MODE:
      return {
        ...state,
        isSingleMode: action.payload
      }
    default:
      return state
  }
}

// Action creator to handle errors
export const errorHandler = (dispatch: Dispatch, error: BetError, type: BetTypes) => {
  let errorMessage = ''

  if (!error.data) {
    dispatch({
      type,
      payload: 'An error occurred'
    })
  }

  if (error.data.error) {
    errorMessage = error.data.error
  } else if (error.data) {
    errorMessage = error.data
  }

  dispatch({
    type,
    payload: errorMessage
  })
}

export const getBetsByStatus = (status: BetStatus) => {
  return (dispatch: Dispatch) => {
    axios.get(`${API_URL}/bets/status/${status}`)
      .then((response) => {
        if (status === BetStatus.ACCEPTED) {
          dispatch({
            type: BetTypes.SET_ACCEPTED_BETS,
            payload: response.data.bets
          })
        } else if (status === BetStatus.OPEN) {
          dispatch({
            type: BetTypes.SET_OPEN_BETS,
            payload: response.data.bets
          })
        } else if (status === BetStatus.SETTLED) {
          dispatch({
            type: BetTypes.SET_SETTLED_BETS,
            payload: response.data.bets
          })
        } else if (status === BetStatus.EXPIRED) {
          dispatch({
            type: BetTypes.SET_EXPIRED_BETS,
            payload: response.data.bets
          })
        } else {
          errorHandler(dispatch, {} as any, BetTypes.BET_ERROR)
        }
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, BetTypes.BET_ERROR)
      })
  }
}

export const addToBetslip = (fixtureId: string, specificBetId: string) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: BetTypes.ADD_TO_BETSLIP,
      payload: { fixtureId, specificBetId, amount: DEFAULT_BET_AMOUNT }
    })
  }
}
export const setBetSlipAmount = (betSlipItem: IBetSlipItem, amount: number) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: BetTypes.SET_BETSLIP_AMOUNT,
      payload: { betSlipItem, amount }
    })
  }
}
export const removeFromBetslip = (fixtureId: string, specificBetId: string) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: BetTypes.REMOVE_FROM_BETSLIP,
      payload: { fixtureId, specificBetId }
    })
  }
}

export const clearBetslip = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: BetTypes.CLEAR_BETSLIP
    })
  }
}

export const toggleShowBetslip = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: BetTypes.TOGGLE_SHOW_BETSLIP
    })
  }
}

export interface CreateBetInfo {
  club: IClub['_id'],
  amount: number,
  betInfos: Array<IBetOption>,
  parlayDisplayName?: string,
  parlayOpposingDisplayName?: string,
  parlayOdds?: number,
}
export const createBet = ({ club, amount, betInfos, parlayDisplayName, parlayOpposingDisplayName, parlayOdds }: CreateBetInfo) => {
  return (dispatch: Dispatch) => {
    axios.post(`${API_URL}/bet`, { club, amount, betInfos, parlayDisplayName, parlayOpposingDisplayName, parlayOdds })
      .then((response) => {
        dispatch({
          type: BetTypes.CREATE_OPEN_BET,
          payload: response.data.newBet
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, BetTypes.BET_ERROR)
      })
  }
}

export const getBetDetail = (betId: IBet['_id']) => {
  return (dispatch: Dispatch) => {
    axios.get(`${API_URL}/bet/${betId}`)
      .then((response) => {
        dispatch({
          type: BetTypes.CURRENT_BET_DETAIL,
          payload: response.data.bet
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, BetTypes.BET_ERROR)
      })
  }
}

export interface AcceptBetInfo {
  betId: IBet['_id']
}
export const acceptBet = ({ betId }: AcceptBetInfo) => {
  return (dispatch: Dispatch) => {
    axios.put(`${API_URL}/bet/accept`, { betId })
      .then((response) => {
        dispatch({
          type: BetTypes.ACCEPT_OPEN_BET,
          payload: response.data.bet
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, BetTypes.BET_ERROR)
      })
  }
}

export interface CancelBetInfo {
  betId: IBet['_id']
}
export const cancelBet = ({ betId }: AcceptBetInfo) => {
  return (dispatch: Dispatch) => {
    return axios.put(`${API_URL}/bet/cancel`, { betId })
      .catch((error) => {
        errorHandler(dispatch, error.response, BetTypes.BET_ERROR)
      })
  }
}

export interface SettleBetInfo {
  betId: IBet['_id']
}
export const settleBet = ({ betId }: AcceptBetInfo) => {
  return (dispatch: Dispatch) => {
    axios.put(`${API_URL}/bet/settle`, { betId })
      .catch((error) => {
        errorHandler(dispatch, error.response, BetTypes.BET_ERROR)
      })
  }
}

export interface NudgeLoserInfo {
  betId: IBet['_id']
}
export const nudgeLoser = ({ betId }: NudgeLoserInfo) => {
  return (dispatch: Dispatch) => {
    axios.put(`${API_URL}/bet/nudge`, { betId })
      .catch((error) => {
        errorHandler(dispatch, error.response, BetTypes.BET_ERROR)
      })
  }
}

export const setIsSingleMode = (val: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: BetTypes.SET_IS_SINGLE_MODE,
      payload: val
    })
  }
}

export const isValidParlay = (bets: Array<IBetOption>) => {
  for (let i = 0; i < bets.length; i++) {
    const bet = bets[i]
    if (bet === undefined) {
      continue
    }

    // Disallow opposing sides of same bet type
    const oppSide = find(bets, {
      fixtureId: bet.fixtureId,
      betType: bet.betType,
      betSide: bet.betSide === BetSide.ONE ? BetSide.TWO : BetSide.ONE
    })
    if (oppSide) {
      return false
    }

    if (bet.betType === BetType.SPREAD) {
      const isFavorite = bet.handicap[0] === '-'
      if (isFavorite) {
        // Disallow favorite spread and favorite money line
        const favoriteML = find(bets, {
          fixtureId: bet.fixtureId,
          betType: BetType.MONEYLINE,
          betSide: bet.betSide
        })
        if (favoriteML) {
          return false
        }

        // Disallow favorite spread and underdog money line
        const underdogML = find(bets, {
          fixtureId: bet.fixtureId,
          betType: BetType.MONEYLINE,
          betSide: bet.betSide === BetSide.ONE ? BetSide.TWO : BetSide.ONE
        })
        if (underdogML) {
          return false
        }
      } else {
        // Disallow underdog spread and underdog money line
        const underdogML = find(bets, {
          fixtureId: bet.fixtureId,
          betType: BetType.MONEYLINE,
          betSide: bet.betSide
        })
        if (underdogML) {
          return false
        }
      }
    }
  }

  return true
}
