import React from 'react'
import { Tab } from 'semantic-ui-react'
import styles from './styles.module.scss'

const HowToPlay: React.FC<{}> = () => {
  const panes = [
    {
      menuItem: 'Placing Bets',
      render: () => <Tab.Pane attached={false}>
        <p>Congratulations, you've been invited to join the Wishbone community. Now it's time to start betting. You have two options:</p>
        <h4>Accept an existing Bet Offer</h4>
        <ul className={styles.smallList}>
          <li>Navigate to the Offers tab. Here, you can view, search for, and sort through and all of the offers that other users have posted.</li>
          <li>Once you find a bet offer that you wish to take, pressing the coin button will open your Lock Bet Confirmation. Here, you can view the details of the event: who you're betting with, your pick(s), and how much you stand to win or lose</li>
          <li>When you're ready to bet, press the green "Lock Bet" button.</li>
          <li>Your bet is locked. It can now be viewed in the Locked section of the My Bets tab</li>
        </ul>
        <h4>Create and Post a Bet</h4>
        <p>If you can’t find an offer that you’re interested in…</p>
        <ul className={styles.smallList}>
          <li>Navigate to the Post a Bet tab. Here, you'll have access to up-to-date betting lines for all upcoming events.</li>
          <li>When you find a bet that you're interested in making, selecting it will create your it to create your Bet Offer Slip. Here, you can select/add additional bets or to create parlays with your selections</li>
          <li>Once you're satisfied with your picks in the Bet Offer Slip, pressing "Open Bet Offer Confirmation" will- you guessed it- show your Bet Offer Confirmation window where you can review the details of your picks</li>
          <li>When you're ready to post your offer, press the green "Confirm" button</li>
          <li>Before someone locks your offer, you also have the ability to view and/or cancel your post in the Open Offers section in the My Bets tab</li>
        </ul>
      </Tab.Pane>,
    },
    {
      menuItem: 'Tracking Bets',
      render: () => <Tab.Pane attached={false}>
        <p>You’ve placed your first bet with another user, now what?</p>
        <ul className={styles.smallList}>
          <li>View and track your bet offers, active bets, and bet history in the My Bets tab.</li>
          <li>Offers that you have made to the community that are waiting to be accepted will appear in the Open Offers section.</li>
          <li>Once your bet has been locked by another user, it will move into the Locked section. Here, you can see your opponent, as well as if your event has started</li>
          <li>Immediately after the event has ended, the bet will move into the Closed {'>'} Pending section where you can view the result of your wager.</li>
        </ul>
      </Tab.Pane>,
    },
    {
      menuItem: 'Settling Up',
      render: () => <Tab.Pane attached={false}>
        <p>Your event has ended and it’s time to settle up. Here’s what you do:</p>
        <h4>If you Won...</h4>
        <ol className={styles.smallList}>
          <li>Congratulations! Might be time to think about quitting your 9 to 5</li>
          <li>Request the proper amount from your opponent on Venmo, Cashapp, etc.</li>
          <li>Once you receive payment, navigate to My Bets press the "Mark as Paid" button to move your wager from Pending to the Settled section</li>
          <li>Reinvest your winnings (responsibly)</li>
        </ol>
        <h4>If you Lost...</h4>
        <ol className={styles.smallList}>
          <li>Blame the refs, they really burned you</li>
          <li>Wait for a payment request from your opponent</li>
          <li>Once you complete the request, press the "Mark as Paid" button. This will notify your opponent. Once he confirms, the wager will move to the Settled section</li>
          <li>Get back on the horse (responsibly)</li>
        </ol>
      </Tab.Pane>,
    },
  ]

  return (
    <div className="container">
      <h2 style={{ textAlign: 'center', marginBottom: '30px' }}>How does Wishbone work?</h2>
      <Tab menu={{ secondary: true }} panes={panes} className="centeredMenu" />
    </div>
  )
}

export default HowToPlay
