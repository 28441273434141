import { History } from 'history'
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form'

import adminReducer from '../modules/admin'
import authReducer from '../modules/auth'
import betsReducer from '../modules/bets'
import bet365Reducer from '../modules/bet365'
import clubReducer from '../modules/clubs'
import chatReducer from '../modules/chat'
import profilesReducer from '../modules/profiles'
import notificationsReducer from '../modules/notifications'

const rootReducer = (history: History<any>) => combineReducers({
  router: connectRouter(history),
  form: formReducer,
  admin: adminReducer,
  auth: authReducer,
  bets: betsReducer,
  bet365: bet365Reducer,
  club: clubReducer,
  chat: chatReducer,
  profiles: profilesReducer,
  notifs: notificationsReducer
})

export default rootReducer
