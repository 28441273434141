import React from 'react'
import { connect } from 'react-redux'
import { FiXCircle } from 'react-icons/fi'
import dayjs from 'dayjs'
import { IBetSlipItem, IBetOption } from 'client/types'
import { removeFromBetslip, setBetSlipAmount, toggleShowBetslip } from 'client/modules/bets'
import styles from './styles.module.scss'

interface Props {
  key: string | number,
  betSlip: Array<IBetSlipItem>,
  betSlipItem: IBetSlipItem,
  specificBetInfo: IBetOption,
  betIsOnTeam?: string,
  betDisplayName: string,
  removeFromBetslip: (fixtureId: string, specificBetId: string) => any,
  setBetSlipAmount: (betSlipItem: IBetSlipItem, amount: number) => any,
  toggleShowBetslip: () => any
}
const BetSlipItem: React.FC<Props> = (props: Props) => {
  const payout = (props.betSlipItem.amount * (props.specificBetInfo.odds - 1)).toFixed(2)

  return (
    <div key={props.key} className={styles.betslipItem}>
      <div className={styles.betslipItem__title}>
        {props.betDisplayName}
      </div>
      <div className={styles.betslipItem__subtitle}>
        {dayjs(props.specificBetInfo.eventStartTime).format('M/D h:mm A')}
      </div>
      <div className={styles.betslipItem__money}>
        <div className={styles.moneyInput}>
          <span>Risk</span>
          <div className={styles.moneyInput__label}>$</div>
          <input
            type={'text'}
            value={props.betSlipItem.amount}
            onChange={(e: any) => {
              const value = parseFloat(e.target.value)
              if (value < 0 || isNaN(value)) {
                props.setBetSlipAmount(props.betSlipItem, 0)
              } else {
                props.setBetSlipAmount(props.betSlipItem, value)
              }
            }}
          />
        </div>
        <div className={styles.moneyInput}>
          <span>To Win</span>
          <div className={styles.moneyInput__label}>$</div>
          <input
            type={'text'}
            disabled={true}
            value={payout}
          />
        </div>
      </div>
      <div
        className={styles.betslipItem__delete}
        onClick={async () => {
          if (props.betSlip.length === 1) {
            await props.toggleShowBetslip()
          }
          await props.removeFromBetslip(props.betSlipItem.fixtureId, props.betSlipItem.specificBetId)
        }}
      ><FiXCircle/></div>
    </div>
  )
}

const mapStateToProps = ({ bets }) => ({
  betSlip: bets.betSlip
})
const mapDispatchToProps = {
  removeFromBetslip,
  setBetSlipAmount,
  toggleShowBetslip
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BetSlipItem)
