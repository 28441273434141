import axios from 'axios'
import { Dispatch, Action } from 'redux'
import { INotification } from 'client/types'
import { API_URL } from 'client/utils/api'

// Types
export enum NotifTypes {
  NOTIF_ERROR = 'notifications/NOTIF_ERROR',
  LATEST_NOTIFS = 'notifications/LATEST_NOTIFS',
  MARK_READ = 'notifications/MARK_READ'
}

interface NotifAction extends Action {
  payload?: any
}

interface NotifError {
  data: any
  status: number
  response: any
}

interface NotifState {
  error?: NotifError
  notifs?: Array<INotification>
}

// Reducer
const INITIAL_STATE: NotifState = {
  error: undefined,
  notifs: undefined
}

export default function (state = INITIAL_STATE, action: NotifAction) {
  switch (action.type) {
    case NotifTypes.NOTIF_ERROR:
      return { ...state, error: action.payload }
    case NotifTypes.LATEST_NOTIFS:
      return { ...state, notifs: action.payload }
    case NotifTypes.MARK_READ:
      const newNotifs = state.notifs.map(n => {
        if (action.payload.includes(n._id)) {
          return { ...n, isRead: true }
        }
        return n
      })
      return { ...state, notifs: newNotifs }
    default:
      return state
  }
}

// Action creator to handle errors
export const errorHandler = (dispatch: Dispatch, error: NotifError, type: NotifTypes) => {
  let errorMessage = ''

  if (!error.data) {
    dispatch({
      type,
      payload: 'An error occurred'
    })
  }

  if (error.data.error) {
    errorMessage = error.data.error
  } else if (error.data) {
    errorMessage = error.data
  }

  dispatch({
    type,
    payload: errorMessage
  })
}

export const getNotifications = () => {
  return (dispatch: Dispatch) => {
    axios.get(`${API_URL}/notifications`)
      .then((response) => {
        dispatch({
          type: NotifTypes.LATEST_NOTIFS,
          payload: response.data.notifications
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, NotifTypes.NOTIF_ERROR)
      })
  }
}

export const markNotificationsRead = (notificationIds: Array<string>) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: NotifTypes.MARK_READ,
      payload: notificationIds
    })
    axios.post(`${API_URL}/notifications/read`, { notifications: notificationIds })
      .catch((error) => {
        errorHandler(dispatch, error.response, NotifTypes.NOTIF_ERROR)
      })
  }
}
