import React from 'react'
import { IUser } from 'client/types'
import styles from './styles.module.scss'

interface AvatarProps {
  user: IUser
}

const Avatar: React.FunctionComponent<AvatarProps> = (props) => {
  const { firstName, lastName } = props.user
  const letters = `${firstName ? firstName.charAt(0) : '?'}${lastName ? lastName.charAt(0) : '?'}`
  return (
    <div className={styles.avatar}>
      <div className={styles.avatar__letters}>
        {letters}
      </div>
    </div >
  )
}

export default Avatar
