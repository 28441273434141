import { Document } from 'mongoose'

/**
 * User
 */
export interface IUser {
  _id: string,
  firstName: string,
  lastName: string,
  paymentMethod: string,
  paymentHandle: string,
  createdAt: Date,
  role: 'ADMIN' | 'USER',
  email: string,
  password?: string,
  clubs: Array<IClub['_id']>,
  isEmailVerified?: boolean,
  emailVerificationToken?: string,
  passwordResetToken?: string,
  comparePassword?: (candidatePassword: string, cb: any) => any
}

export interface IUserProfile extends IUser {
  clubsExtended: Array<IClub>,
  openBets: Array<IBet>,
  acceptedBets: Array<IBet>,
  unsettledBets: Array<IBet>,
  settledBets: Array<IBet>
}

/**
 * Applications
 */

export enum ApplicationStatus {
  APPLIED = 'APPLIED',
  APPROVED = 'APPROVED',
  JOINED = 'JOINED',
  DENIED = 'DENIED',
}

export interface IApplication {
  _id: string,
  createdAt: Date,
  status: ApplicationStatus,
  club: IClub['_id'],
  clubName: string
  name: string,
  email: string,
  referrer: IUser['_id'],
  referrerName: string,
  joinClubToken: string
}

/**
 * Credentials
 */

// revoked for kicks, inactive for temporary leaves
export enum CredentialStatus {
  ISSUED = 'ISSUED',
  ACTIVE = 'ACTIVE',
  REVOKED = 'REVOKED',
  INACTIVE = 'INACTIVE'
}

export interface ICredential {
  _id: string,
  createdAt: Date,
  status: CredentialStatus,
  club: IClub['_id'],
  referrer: IUser['_id'],
  referrerName: string
}

/**
 * Clubs
 */
export interface IClub {
  _id: string,
  createdAt: Date,
  name: string,
  users: Array<IUser['_id']>,
  admins: Array<IUser['_id']>,
  applications: Array<IApplication & Document>,
  chatGroup: IChatGroup['_id']
}

/**
 * Bets
 */
export enum BetStatus {
  OPEN = 'OPEN',
  ACCEPTED = 'ACCEPTED',
  UNSETTLED = 'UNSETTLED',
  SETTLED = 'SETTLED',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED'
}
export enum BetType {
  MONEYLINE = 'MONEY LINE',
  SPREAD = 'SPREAD',
  OVERUNDER = 'TOTAL',
  UNSUPPORTED = 'UNSUPPORTED'
}
export enum BetSide {
  ONE = 'ONE',
  TWO = 'TWO',
  DRAW = 'DRAW'
}

export interface IBetOption {
  betType: BetType,
  betSide: BetSide,
  fixtureId: string,
  specificBetId: string,
  odds: number,
  opposingOdds: number,
  americanOdds: string,
  displayName: string,
  opposingDisplayName: string,
  handicap?: string,
  opposingHandicap?: string,
  sportId: string,
  leagueId: string,
  fixtureHomeName: string,
  fixtureAwayName: string,
  eventStartTime: Date
}

export interface IBet {
  _id: string,
  createdAt: Date,
  users: Array<IUser['_id']>,
  createdBy: IUser | any,
  acceptedBy?: IUser | any,
  acceptedAt?: Date,
  settledUsers: Array<IUser['_id']>,
  settledAt?: Date,
  club: IClub['_id'],
  status: BetStatus,
  isParlay: boolean,
  firstEventStartTime: Date,
  lastEventStartTime: Date,
  didEventsEnd: boolean,
  markedEndedAt?: Date,
  didCreatorWin?: boolean,
  didWinnerNudge: boolean,
  displayName: string,
  opposingDisplayName: string,
  odds: number,
  opposingOdds: number,
  americanOdds: string,
  amount: number,
  payout: number,
  opposingPayout: number,
  betInfos: Array<IBetOption>
}

export interface IBetSlipItem {
  fixtureId: string,
  specificBetId: string,
  amount: number
}
export enum BetTypeDisplay {
  'MONEY LINE' = 'Moneyline',
  'SPREAD' = 'Spread',
  'TOTAL' = 'Total'
}

/**
 * Bet365
 */
export interface IGameLine365 {
  id: string,
  odds: string,
  name: string,
  header?: string,
  handicap?: string
}
export interface IFixtureOdds365 {
  FI: string,
  event_id: string,
  main: {
    updated_at: string,
    sp: {
      game_lines?: {
        id: string,
        name: string,
        odds: Array<IGameLine365>
      },
      full_time_result?: {
        id: string,
        name: string,
        odds: Array<IGameLine365>
      }
    }
  }
}
export interface IFixture365 {
  id: string,
  sport_id: string,
  time: string,
  league: {
    id: string,
    name: string
  },
  home: {
    id: string,
    name: string
  },
  away: {
    id: string,
    name: string
  },
  // corresponds to "event_id" in IFixtureOdds
  our_event_id: string,
  updated_at: string,
  odds: IFixtureOdds365
}

export interface IFixture {
  fixtureId: string,
  sportId: string,
  startTime: string,
  league: {
    id: string,
    name: string
  },
  home: {
    id: string,
    name: string
  },
  away: {
    id: string,
    name: string
  },
  odds: Array<IBetOption>
}

export interface INotification {
  _id: string,
  createdAt: Date,
  user: IUser,
  displayName: string,
  linkTo?: string,
  isRead: boolean,
}

export interface IChat {
  _id: string,
  createdAt: Date,
  message: string,
  user: IUser['_id'],
  name: string,
  chatGroup: IChatGroup['_id']
}

export interface IChatGroup {
  _id: string,
  createdAt: Date,
  members: Array<IUser['_id']>,
  chats: Array<IChat & Document>
}

export interface ISocketChat {
  chatGroupId: IChatGroup['_id'],
  userId: IUser['_id'],
  name: string,
  message: string
}
