import React from 'react'
import { IBet } from 'client/types'
import OpenBet from './OpenBet'
import styles from './styles.module.scss'

const OpenBetGroup: React.FC<{ bets: Array<IBet> }> = (props) => {
  const totalRisk = props.bets.reduce((total, b) => {
    return total + b.opposingPayout
  }, 0)

  const totalWin = props.bets.reduce((total, b) => {
    return total + b.payout
  }, 0)

  return (
    <div className={styles.betGroup}>
      <div className={styles.betGroup__head}>
        <div>Pick</div>
        <div>Risk</div>
        <div>To Win</div>
        <div>Expires</div>
        <div> </div>
      </div>
      <div className={styles.betGroup__body}>
        {props.bets.map(bet =>
          <OpenBet bet={bet} key={bet._id} />
        )}
      </div>
      <div className={styles.betGroup__footer}>
        <div><strong>Total:</strong></div>
        <div>${totalRisk.toFixed(2)}</div>
        <div>${totalWin.toFixed(2)}</div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default OpenBetGroup
