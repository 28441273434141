import React from 'react'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import { IBet, IUser } from 'client/types'
import styles from './styles.module.scss'

const SettledBetItem: React.FC<{ bet: IBet, user: IUser }> = (props) => {
  const { bet, user } = props
  const isCreator = bet.createdBy._id === user._id
  const displayName = isCreator ? bet.displayName : bet.opposingDisplayName
  const risk = isCreator ? bet.opposingPayout : bet.payout
  const toWin = isCreator ? bet.payout : bet.opposingPayout
  const againstUser = isCreator ? bet.acceptedBy : bet.createdBy
  const didWin = isCreator ? bet.didCreatorWin : !bet.didCreatorWin

  return (
    <div className={`${styles.betItem} ${didWin ? styles.betItem__win : styles.betItem__loss}`}>
      <div>
        <div>{dayjs(bet.lastEventStartTime).format('M/D')}</div>
      </div>
      <div>{displayName}</div>
      <div>${risk.toFixed(2)}</div>
      <div>${toWin.toFixed(2)}</div>
      <div>
        {againstUser.firstName[0].toUpperCase()}{againstUser.lastName[0].toUpperCase()}
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user
})

export default connect(
  mapStateToProps,
)(SettledBetItem)
