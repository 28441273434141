import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Route, Switch, Link } from 'react-router-dom'
import { Menu, Sidebar } from 'semantic-ui-react'
import { useMediaQuery } from 'react-responsive';
import ScrollToTop from 'client/components/ScrollToTop'
import Masthead from 'client/components/Masthead'
import Navigation from 'client/scenes/Navigation'
import Login from 'client/scenes/Auth/scenes/Login'
import TriggerReset from 'client/scenes/Auth/scenes/TriggerReset'
import ResetPassword from 'client/scenes/Auth/scenes/ResetPassword'
import Register from 'client/scenes/Auth/scenes/Register'
import Join from 'client/scenes/Auth/scenes/Join'
import AdminDash from 'client/scenes/Admin'
import VerifyEmail from 'client/scenes/Auth/scenes/VerifyEmail'
import Upcoming from 'client/scenes/Upcoming'
import About from 'client/scenes/Static/About'
import HowToPlay from 'client/scenes/Static/HowToPlay'
import Community from 'client/scenes/Static/Community'
import Juice from 'client/scenes/Static/Juice'
import NotFound from 'client/components/NotFound'
import OpenBets from 'client/scenes/OpenBets'
import Profile from 'client/scenes/Profile'
import Club from 'client/scenes/Club'
import BetSlip from 'client/scenes/BetSlip'
import RequireAuth from 'client/scenes/Auth/services/RequireAuth'
import RequireAdmin from 'client/scenes/Auth/services/RequireAdmin'
import { AuthState } from './modules/auth'
import 'client/styles/styles.scss'

interface AppProps {
  history: any
  location: any
  match: any
  auth: AuthState
}

const App: React.FC<AppProps> = (props: AppProps) => {
  const [sidebarVisible, setSidebarVisible] = useState(false)

  const isMobile = useMediaQuery({ query: '(max-width: 1080px)' });
  const onStaticPage = props.location.pathname.indexOf('wishbone') !== -1

  const toggleSidebarVisible = () => {
    setSidebarVisible(!sidebarVisible)
  }

  const appContent = () => {
    return (
      <>
        <ScrollToTop />
        {!isMobile && props.auth.authenticated && !onStaticPage &&
          <Navigation />
        }
        <Masthead toggleSidebarVisible={toggleSidebarVisible} />
        <div className='app-content'>
          <Switch>
            <Route exact path='/' component={RequireAuth(OpenBets)} />
            <Route path='/login' component={Login} />
            <Route path='/reset' component={TriggerReset} />
            <Route path='/reset-password' component={ResetPassword} />
            {/* <Route path='/register' component={Register} /> */}
            <Route path='/join/:token' component={Join} />
            <Route path='/verify' component={VerifyEmail} />
            <Route path='/admin' component={RequireAdmin(AdminDash)} />
            <Route path='/post' component={RequireAuth(Upcoming)} />
            <Route path='/my-bets' component={RequireAuth(Profile)} />
            <Route path='/club' component={RequireAuth(Club)} />
            <Route path='/wishbone/about' component={About} />
            <Route path='/wishbone/how-to-play' component={HowToPlay} />
            <Route path='/wishbone/community-guidelines' component={Community} />
            <Route path='/wishbone/what-is-juice' component={Juice} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </>
    )
  }

  return (
    <div>
      <BetSlip />
      {isMobile && props.auth.authenticated && !onStaticPage &&
        <Navigation />
      }
      <Sidebar.Pushable style={{ minHeight: '100vh' }}>
        <Sidebar
          as={Menu}
          animation='overlay'
          icon='labeled'
          inverted
          onHide={() => setSidebarVisible(false)}
          vertical
          visible={sidebarVisible}
          width='wide'
        >
          <Menu.Item as={Link} to='/' onClick={() => setSidebarVisible(false)}>
            Home
          </Menu.Item>
          <Menu.Item as={Link} to='/wishbone/about' onClick={() => setSidebarVisible(false)}>
            About Wishbone
          </Menu.Item>
          <Menu.Item as={Link} to='/wishbone/how-to-play' onClick={() => setSidebarVisible(false)}>
            How to Play
          </Menu.Item>
          <Menu.Item as={Link} to='/wishbone/community-guidelines' onClick={() => setSidebarVisible(false)}>
            Community Guidelines
          </Menu.Item>
          <Menu.Item as={Link} to='/wishbone/what-is-juice' onClick={() => setSidebarVisible(false)}>
            What is Juice?
          </Menu.Item>
        </Sidebar>

        <Sidebar.Pusher style={{ minHeight: '100vh' }}>
          {appContent()}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    location: state.router.location
  }
}
export default connect(
  mapStateToProps
)(withRouter(App))
