import axios from 'axios'
import { Dispatch, Action } from 'redux'
import { IFixture } from 'client/types'
import { leagueIdToName } from 'client/utils/bet365mappings'
import { API_URL } from 'client/utils/api'

// Types
export enum Bet365Types {
  SET_IS_LOADING = 'bet365/SET_IS_LOADING',
  SET_UPCOMING_FIXTURES = 'bet365/SET_UPCOMING_FIXTURES',
  GET_LEAGUES = 'bet365/GET_LEAGUES'
}

interface Bet365Error {
  data: any
  status: number
  response: any
}

interface Bet365State {
  error?: Bet365Error
  upcomingFixtures?: {[sportId: string]: Array<IFixture>},
  isLoading: boolean,
  leagues?: any
}

// Reducer
const INITIAL_STATE: Bet365State = {
  error: undefined,
  upcomingFixtures: {},
  isLoading: false,
  leagues: []
}

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case Bet365Types.SET_UPCOMING_FIXTURES:
      const { leagueId, fixtures } = action.payload
      return {
        ...state,
        upcomingFixtures: {
          ...state.upcomingFixtures,
          [leagueId]: fixtures
        }
      }
    case Bet365Types.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }
    default:
      return state
  }
}

export const setIsLoading = (isLoading: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Bet365Types.SET_IS_LOADING,
      payload: isLoading
    })
  }
}

export const getUpcomingFixtures = (leagueId: string) => {
  return async (dispatch: Dispatch) => {
    const requests: any = []
    if (leagueId === 'All') {
      Object.keys(leagueIdToName).map((leagueId: string, index: number) => {
        requests.push(axios.get(`${API_URL}/fixtures/${leagueId}`))
      })
      // @ts-ignore
      const r = await Promise.allSettled(requests)
      r.map((item: any, index: number) => {
        const urlSplit: string = item.value.config.url.split('/')
        const lId: string = urlSplit[urlSplit.length - 1]
        dispatch({
          type: Bet365Types.SET_UPCOMING_FIXTURES,
          payload: {
            leagueId: lId,
            fixtures: item.value.data.fixtures
          }
        })
      })
    } else {
      const f = await axios.get(`${API_URL}/fixtures/${leagueId}`)
      dispatch({
        type: Bet365Types.SET_UPCOMING_FIXTURES,
        payload: {
          leagueId,
          fixtures: f.data.fixtures
        }
      })
    }
  }
}

export const getLeagues = (sportId: string) => {
  return (dispatch: Dispatch) => {
    axios.get(`${API_URL}/league/$${sportId}`)
      .then((response) => {
        dispatch({
          type: Bet365Types.GET_LEAGUES,
          payload: response.data
        })
      })
      .catch((error) => {
        return error
      })
  }
}
