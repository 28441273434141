import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Route } from 'react-router-dom'
import axios from 'axios'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import store, { history } from 'client/store'
import cookies from 'client/utils/cookies'

import App from './App'
import { AuthTypes } from 'client/modules/auth'

// Load JWT tokens
const token = cookies.get('token')

if (token) {
  store.dispatch({ type: AuthTypes.AUTH_USER, payload: {user: undefined, isAdmin: undefined} })
}

// init dayjs's relativeTime plugin
dayjs.extend(relativeTime)

// Attaches Authorization headers to all requests
axios.defaults.headers.common.Authorization = cookies.get('token')

const render = (Component: any) => {
  ReactDOM.render(
    <AppContainer>
      <Provider store={store as any}>
        <ConnectedRouter history={history}>
          <Route component={Component} />
        </ConnectedRouter>
      </Provider>
    </AppContainer>
  , document.getElementById('root'))
}

render(App)

// feelin HOT HOT HOT
if (module.hot) {
  module.hot.accept()
}
