// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".betGroup_1VeZ3L{margin-top:20px}.betGroup__head_SFZISR{font-weight:bold;margin-bottom:10px}.betGroup__footer_Iodrmc{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center}.betGroup__footer_Iodrmc>div:first-of-type{font-weight:bold;margin:30px 0 10px}.betGroup__footer_Iodrmc>div:not(:first-of-type){padding:3px 0;width:200px;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between}.betItem_1MhSBS{padding:20px 0}.betItem_1MhSBS:not(:last-of-type){border-bottom:solid 1px #ddd}.betItem__footer_3VQ9zG{padding:10px 0 0;text-align:center}.betItem__nudge_iIsDan{margin-left:5px}.betItem__settle_1uGmUJ,.betItem__nudge_iIsDan{cursor:pointer;border:none;color:#fff;background:#464650;padding:5px 10px;border-radius:3px;font-size:12px}.betItem__settle_1uGmUJ:hover,.betItem__nudge_iIsDan:hover{background:#646466}.betItem__settle_1uGmUJ:disabled,.betItem__nudge_iIsDan:disabled{cursor:default;background:#848486}.betGroup__head_SFZISR,.betItem__body_Kyf_8R{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;font-size:10px}@media screen and (min-width: 768px){.betGroup__head_SFZISR,.betItem__body_Kyf_8R{font-size:14px}}.betGroup__head_SFZISR>div,.betItem__body_Kyf_8R>div{-ms-flex-negative:0;flex-shrink:0;-ms-flex-positive:0;flex-grow:0}.betGroup__head_SFZISR>div:nth-child(1),.betItem__body_Kyf_8R>div:nth-child(1){width:40%;padding-right:15px}.betGroup__head_SFZISR>div:nth-child(2),.betItem__body_Kyf_8R>div:nth-child(2){width:12%;padding-right:10px}.betGroup__head_SFZISR>div:nth-child(3),.betItem__body_Kyf_8R>div:nth-child(3){width:12%;padding-right:10px}.betGroup__head_SFZISR>div:nth-child(4),.betItem__body_Kyf_8R>div:nth-child(4){width:28%}.betGroup__head_SFZISR>div:nth-child(5),.betItem__body_Kyf_8R>div:nth-child(5){width:8%}\n", ""]);
// Exports
exports.locals = {
	"betGroup": "betGroup_1VeZ3L",
	"betGroup__head": "betGroup__head_SFZISR",
	"betGroup__footer": "betGroup__footer_Iodrmc",
	"betItem": "betItem_1MhSBS",
	"betItem__footer": "betItem__footer_3VQ9zG",
	"betItem__nudge": "betItem__nudge_iIsDan",
	"betItem__settle": "betItem__settle_1uGmUJ",
	"betItem__body": "betItem__body_Kyf_8R"
};
module.exports = exports;
