import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history'
import { logger } from 'redux-logger'

import rootReducer from './reducers'

export const history = createBrowserHistory()

const initialState = {}
const enhancers = []
let middleware = [
  thunk,
  routerMiddleware(history)
]

// console.log('node env??', process.env.NODE_ENV)
if (process.env.NODE_ENV !== 'production') {
  middleware.push(logger)

  const { devToolsExtension } = window as any

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

// Remove undefined middleware
middleware = middleware.filter((e) =>  {
  return typeof e === 'function'
})

// Redux enhancers
const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

const store = createStore(
  rootReducer(history),
  initialState,
  composedEnhancers
)

export default store
