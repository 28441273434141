import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import FixturePreview from 'client/components/FixturePreview'
import { getUpcomingFixtures, setIsLoading } from '../../modules/bet365'
import { IFixture } from 'client/types'
import { leagueOptions } from 'client/utils/bet365mappings'
import ActivityMonitor from 'client/components/ActivityMonitor'
import styles from './styles.module.scss'
import { chain } from 'lodash'
interface Props {
  isLoading: boolean,
  upcomingFixtures: {[sportId: string]: Array<IFixture>},
  getUpcomingFixtures: (sportId: string) => any,
  setIsLoading: (isLoading: boolean) => any
}
const Upcoming: React.FC<Props> = (props: Props) => {
  const [selectedLeague, setSelectedLeague] = useState('All')
  const [searchQuery, setSearchQuery] = useState('')

  const getData = async () => {
    await props.setIsLoading(true)
    await props.getUpcomingFixtures('All')
    await props.setIsLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  const renderListItems = () => {
    const displayFixtures = chain(props.upcomingFixtures)
      .map(category => category)
      .flatten()
      .filter(fixture => {
        if (selectedLeague === 'All') {
          return true
        }

        if (selectedLeague !== fixture.league.id) {
          return false
        }

        return true
      })
      .filter(fixture => {
        // Search filter
        if (!searchQuery) {
          return true
        }

        const query = searchQuery.toLowerCase()
        const fixtureHomeName = fixture.home.name
        const fixtureAwayName = fixture.away.name
        if (fixtureHomeName.toLowerCase().indexOf(query) >= 0 || fixtureAwayName.toLowerCase().indexOf(query) >= 0) {
          return true
        }

        return false
      })
      .sortBy(fixture => {
        return fixture.startTime
      })
      .value()
  
    return displayFixtures.map((fixture, index) => {
      return (
        <FixturePreview fixture={fixture} key={index} />
      )
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.filters}>
        <select
          onChange={(event) => {
            const option = leagueOptions.find(option => option.name === event.target.value)
            setSelectedLeague(option.leagueId)
          }}
        >
          {leagueOptions.map((item) =>
            <option key={item.name} value={item.name}>{item.name}</option>
          )}
        </select>
        <input
          className={styles.filters__search}
          type='text'
          placeholder='Events'
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
        />
      </div>

      {props.isLoading && (
        <div className='loading-container'>
          <ActivityMonitor isLoading={props.isLoading} />
        </div>
      )}
      {!props.isLoading && (
        <div className={styles.fixtureContainer}>
          {renderListItems()}
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({ bet365 }) => ({
  isLoading: bet365.isLoading,
  upcomingFixtures: bet365.upcomingFixtures
})
const mapDispatchToProps = {
  getUpcomingFixtures,
  setIsLoading
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Upcoming)
