import axios from 'axios'
import { Dispatch, Action } from 'redux'

import { history } from 'client/store'
import { IApplication, IUser } from 'client/types'
import cookies from '../../utils/cookies'
import { API_URL } from 'client/utils/api'
import { ClubTypes } from '../clubs'

// Types
export enum AuthTypes {
  SET_DID_REPULL_USER = 'auth/SET_DID_REPULL_USER',
  AUTH_USER = 'auth/AUTH_USER',
  LOGIN_USER = 'auth/LOGIN_USER',
  REGISTER_USER = 'auth/REGISTER_USER',
  UNAUTH_USER = 'auth/UNAUTH_USER',
  AUTH_ERROR = 'auth/AUTH_ERROR',
  VERIFY_EMAIL = 'auth/VERIFY_EMAIL',
  VERIFY_ADMIN = 'auth/VERIFY_ADMIN',
  SELECT_CLUB = 'auth/SELECT_CLUB',
  ROUTE_JOIN = 'auth/ROUTE_JOIN'
}

interface AuthAction extends Action {
  payload?: any
}

interface AuthError {
  data: any
  status: number
  response: any
}

export interface AuthState {
  didRepullUser: boolean
  authenticated: boolean
  isAdmin: boolean
  user?: IUser
  existingUser?: IUser
  application?: IApplication
  selectedClub?: number
  error?: AuthError
}

// Reducer
const INITIAL_STATE: AuthState = {
  didRepullUser: false,
  authenticated: false,
  isAdmin: undefined,
  user: undefined,
  existingUser: undefined,
  application: undefined,
  selectedClub: -1,
}

export default function (state = INITIAL_STATE, action: AuthAction) {
  switch (action.type) {
    case AuthTypes.SET_DID_REPULL_USER:
      return {
        ...state,
        didRepullUser: action.payload
      }
    case AuthTypes.AUTH_USER:
      // dont store password to redux
      const { isAdmin, ...userPayload } = action.payload
      const { password, ...others } = userPayload
      
      return {
        ...state,
        error: '',
        authenticated: true,
        user: others.user,
        isAdmin: isAdmin,
        selectedClub: 0
      }
    case AuthTypes.UNAUTH_USER:
      return { ...state, authenticated: false, selectedClub: -1, isAdmin: false }
    case AuthTypes.VERIFY_EMAIL:
      return { ...state, user: { ...state.user, isEmailVerified: true } }
    case AuthTypes.VERIFY_ADMIN:
      return { ...state, isAdmin: action.payload}
    case AuthTypes.ROUTE_JOIN:
      // console.log("APPLICATION PAYLOAD", action.payload)
      const eUser = (action.payload.joinUser ? action.payload.joinUser : null)
      // dont store password to redux
      if (eUser) {
        delete eUser.password
      }
      return { ...state, application: action.payload.application, existingUser: eUser}
    case AuthTypes.SELECT_CLUB:
      return { ...state, selectedClub: action.payload.club, isAdmin: action.payload.isAdmin }
    case AuthTypes.AUTH_ERROR:
      return { ...state, error: action.payload }
    default:
      return state
  }
}

// Action creators
export const setDidRepullUser = (value: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: AuthTypes.SET_DID_REPULL_USER,
      payload: value
    })
  }
}
// Action creator to remove cookie and set unauthenticated status
// Does not redirect like logoutUser()
export const unauthUser = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: AuthTypes.UNAUTH_USER })
    cookies.remove('token', { path: '/' })
  }
}

// Action creator to set authenticated status
// Assumes the cookie is already set
export const authUser = (user: IUser) => {
  const clubId = user.clubs[0];
  const userId = user._id;
  // console.log('at auth user', user)
  return (dispatch: Dispatch) => {
    axios.post(`${API_URL}/clubs/me`, { clubId })
      .then((response) => {
        // console.log('at verify admin callback', response)
        dispatch({
          type: AuthTypes.AUTH_USER,
          payload: {
            user: user,
            isAdmin: response.data.isAdmin
          }
        })
        dispatch({
          type: ClubTypes.GET_ALL_CLUBS,
          payload: response.data.clubs
        })
        dispatch({
          type: ClubTypes.CURRENT_CLUB,
          payload: response.data.club
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, AuthTypes.AUTH_ERROR)
      })
  }
}

// Action creator to handle errors
export const errorHandler = (dispatch: Dispatch, error: AuthError, type: AuthTypes) => {
  let errorMessage = ''

  if (!error.data) {
    dispatch({
      type,
      payload: 'An error occurred'
    })
  }

  if (error.data.error) {
    errorMessage = error.data.error
  } else if (error.data) {
    errorMessage = error.data
  }

  if (error.status === 401) {
    dispatch({
      type,
      payload: 'You are not authorized to do this. Please login and try again.'
    })
    logoutUser()
  } else {
    dispatch({
      type,
      payload: errorMessage
    })
  }
}

// Action creator to handle logins
export interface LoginInfo {
  email: string
  password: string
}

export const loginUser = ({ email, password }: LoginInfo) => {
  return (dispatch: Dispatch) => {
    axios.post(`${API_URL}/auth/login`, { email, password })
      .then((response) => {
        cookies.set('token', response.data.token, { path: '/', maxAge: 5184000 })

        // Attaches Authorization headers to all requests
        axios.defaults.headers.common.Authorization = cookies.get('token')

        dispatch({
          type: AuthTypes.AUTH_USER,
          payload: {
            user: response.data.user,
            isAdmin: response.data.isAdmin
          }
        })
        dispatch({
          type: ClubTypes.CURRENT_CLUB,
          payload: response.data.club
        })
        dispatch({
          type: ClubTypes.GET_ALL_CLUBS,
          payload: [response.data.club]
        })
      })
      .then((_r) => {
        history.push('/')
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, AuthTypes.AUTH_ERROR)
      })
  }
}

// Action creator to register user
export interface RegisterInfo {
  email: string,
  firstName: string,
  lastName: string,
  paymentMethod: string,
  paymentHandle: string,
  password: string
}

export const registerUser = ({ email, firstName, lastName, paymentMethod, paymentHandle, password }: RegisterInfo) => {
  const paymentMeth = paymentMethod ? paymentMethod : 'Venmo'
  // remove platform-specific starting char from payment method if included
  const paymentHand = (paymentMeth === 'Cash App' ?
    (paymentHandle.charAt(0) === '$' ? paymentHandle.substring(1) : paymentHandle) :
    (paymentHandle.charAt(0) === '@' ? paymentHandle.substring(1) : paymentHandle)
  )
  return (dispatch: Dispatch) => {
    axios.post(`${API_URL}/auth/register`, { email, firstName, lastName, paymentMethod: paymentMeth, paymentHandle: paymentHand, password })
      .then((response) => {
        cookies.set('token', response.data.token, { path: '/', maxAge: 5184000 })

        // Attaches Authorization headers to all requests
        axios.defaults.headers.common.Authorization = cookies.get('token')

        dispatch({
          type: AuthTypes.AUTH_USER,
          payload: {
            user: response.data.user,
            isAdmin: false
          }
        })
      })
      .then((_r) => {
        history.push('/')
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, AuthTypes.AUTH_ERROR)
      })
  }
}

// Action creator to register user directly with a club invite
export interface JoinInfo {
  firstName: string,
  lastName: string,
  paymentMethod: string,
  paymentHandle: string,
  password: string
}

export const joinUser = ({firstName, lastName, paymentMethod, paymentHandle, password }: JoinInfo, token: string) => {
  const paymentMeth = paymentMethod ? paymentMethod : 'Venmo'
  // remove platform-specific starting char from payment method if included
  const paymentHand = (paymentMeth === 'Cash App' ?
    (paymentHandle.charAt(0) === '$' ? paymentHandle.substring(1) : paymentHandle) :
    (paymentHandle.charAt(0) === '@' ? paymentHandle.substring(1) : paymentHandle)
  )
  return (dispatch: Dispatch) => {
    axios.post(`${API_URL}/auth/join/${token}`, { firstName, lastName, paymentMethod: paymentMeth, paymentHandle: paymentHand, password })
      .then((response) => {
        cookies.set('token', response.data.token, { path: '/', maxAge: 5184000 })

        // Attaches Authorization headers to all requests
        axios.defaults.headers.common.Authorization = cookies.get('token')

        dispatch({
          type: AuthTypes.AUTH_USER,
          payload: {
            user: response.data.user,
            isAdmin: false
          }
        })
        dispatch({
          type: ClubTypes.CURRENT_CLUB,
          payload: response.data.club
        })
        dispatch({
          type: ClubTypes.GET_ALL_CLUBS,
          payload: [response.data.club]
        })
      })
      .then((_r) => {
        history.push('/')
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, AuthTypes.AUTH_ERROR)
      })
  }
}

// Action creator to join existing user to club
export interface JoinExistingInfo {
  email: string,
  password: string
}

export const joinExistingUser = ({email, password }: JoinExistingInfo, token: string) => {
  
  return (dispatch: Dispatch) => {
    axios.post(`${API_URL}/auth/join/${token}`, { email, password })
      .then((response) => {
        cookies.set('token', response.data.token, { path: '/', maxAge: 5184000 })

        // Attaches Authorization headers to all requests
        axios.defaults.headers.common.Authorization = cookies.get('token')

        dispatch({
          type: AuthTypes.AUTH_USER,
          payload: {
            user: response.data.user,
            isAdmin: false
          }
        })
        dispatch({
          type: ClubTypes.CURRENT_CLUB,
          payload: response.data.club
        })
        dispatch({
          type: ClubTypes.GET_ALL_CLUBS,
          payload: [response.data.club]
        })
      })
      .then((_r) => {
        history.push('/')
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, AuthTypes.AUTH_ERROR)
      })
  }
}

// Action creator to log out the user
export const logoutUser = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: AuthTypes.UNAUTH_USER })
    cookies.remove('token', { path: '/' })

    history.push('/')
    location.reload()
  }
}

export const triggerPasswordReset = (email: string) => {
  return (dispatch: Dispatch) => {
    axios.post(`${API_URL}/auth/trigger-reset`, { email })
      .catch((error) => {
        errorHandler(dispatch, error.response, AuthTypes.AUTH_ERROR)
      })
  }
}

export interface ResetPasswordInfo {
  token: string,
  newPassword: string
}
export const resetPassword = ({ token, newPassword }: ResetPasswordInfo) => {
  return (dispatch: Dispatch) => {
    axios.post(`${API_URL}/auth/reset-password`, { token, newPassword })
      .catch((error) => {
        errorHandler(dispatch, error.response, AuthTypes.AUTH_ERROR)
      })
  }
}

export const verifyEmail = (token: string) => {
  return (dispatch: Dispatch) => {
    axios.post(`${API_URL}/auth/verify-email`, { token })
      .then((response) => {
        dispatch({
          type: AuthTypes.VERIFY_EMAIL
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, AuthTypes.AUTH_ERROR)
      })
  }
}

export const getApplicationByToken = (token: string) => {
  return (dispatch: Dispatch) => {
    axios.get(`${API_URL}/application/${token}`)
      .then((response) => {
        console.log("APPLICATION RESPONSE", response)
        dispatch({
          type: AuthTypes.ROUTE_JOIN,
          payload: response.data
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, AuthTypes.AUTH_ERROR)
      })
  }
}

export const selectClub = (clubId: string) => {
  return (dispatch: Dispatch) => {
    axios.post(`${API_URL}/clubs/me`, { clubId })
      .then((response) => {
        const index = response.data.clubs.findIndex(c => c._id.toString() === clubId)
        dispatch({
          type: AuthTypes.SELECT_CLUB,
          payload: {
            isAdmin: response.data.isAdmin,
            selectedClub: (index ? index : 0)
          }
        })
        dispatch({
          type: ClubTypes.CURRENT_CLUB,
          payload: response.data.club
        })
        dispatch({
          type: ClubTypes.GET_ALL_CLUBS,
          payload: response.data.clubs
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, AuthTypes.AUTH_ERROR)
      })
  }
}
