import React from 'react'
import styles from './styles.module.scss'

const About: React.FC<{}> = () => {
  return (
    <div className="container">
      <h2 style={{ textAlign: 'center', marginBottom: '30px' }}>What is Wishbone?</h2>
      <p>Wishbone is a new approach to peer-to-peer wagering created by gamblers, for gamblers. Like many of you, we grew tired of the status-quo: sketchy online books and shady bookies who take a cut of every bet you place and profit off of your misery. That was until we were introduced to a GroupMe where bettors engaged with one another in a juice-free ecosystem, posting bets and paying each other out on Venmo. In our path to developing gambling problems, we discovered a gambling solution.</p>
      <h4>How it started: From GroupMe to Wishbone</h4>
      <p className={styles.small}>For over 2 years, we bet actively in this group and watched the membership numbers multiply. As the appetite for <strong>no-juice</strong>, peer-to-peer betting became clear, we began to envision a better future: realizing that with enough volume, one could <strong>completely eliminate the need for a traditional bookie.</strong> However, with growth arose certain issues. With no oversight or structure, the chat feed became messy, and tracking your wagers became increasingly difficult. A few seedy characters were able to penetrate the group and blend into the masses. Sensing that the group was nearing its tipping point, we began to think about how we could improve the experience for gamblers. Our worries were ultimately confirmed when one welcher took down a group of nearly 800 honest gamblers.</p>
      <h4>Our Mission: Peer-to-Peer Betting for all </h4>
      <p className={styles.small}>Wishbone was designed specifically to combat the flaws of that betting group and allow for <strong>scalable</strong> peer-to-peer betting. We’ve taken the beauty of that community -- <strong>no juice, players you can trust, high bet turnover, venmo payouts</strong>-- and bolstered it with new features that we have yearned for since the group’s inception. One day, we plan on being able to accept deposits so that we can fully guarantee payouts and truly scale. But we’re not there yet, and we’re going to <strong>need your help and feedback</strong> to make that happen. All we ask from you is that you stop paying your bookie unnecessary fees. Join our community, continue betting just like you would, and let us know how you think we can improve our platform.</p>
    </div>
  )
}

export default About
