import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'

import { verifyEmail } from 'client/modules/auth'

interface VerifyEmailProps {
  verifyEmail: (s: string) => any
}

const VerifyEmail: React.FC<VerifyEmailProps> = ({ verifyEmail }) => {
  useEffect(
    () => {
      const token: any = queryString.parse(location.search).token
      verifyEmail(token as string)  
    },
    []
  )

  return (
    <div className='container'>
      <h2>Email verified!</h2>
    </div>
  )
}

const mapDispatchToProps = {
  verifyEmail
}

export default connect(
  undefined,
  mapDispatchToProps
)(VerifyEmail)
