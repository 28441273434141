import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { BsBellFill } from 'react-icons/bs'
import { INotification } from 'client/types'
import { getNotifications, markNotificationsRead } from 'client/modules/notifications'
import Notif from './Notif'
import styles from './styles.module.scss'

interface Props {
  notifs: Array<INotification>,
  getNotifications: () => any,
  markNotificationsRead: (notificationIds: Array<string>) => any
}

const Notifications: React.FC<Props> = (props) => {
  const { pathname } = useLocation()
  const history = useHistory()

  useEffect(() => {
    // Fetch notification on route change
    props.getNotifications()
  }, [pathname])

  const [isExpanded, setIsExpanded] = useState(false)
  const containerRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isExpanded && containerRef.current && !containerRef.current.contains(event.target)) {
        setIsExpanded(false)
      }
    }

    document.addEventListener('click', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside);
    }
  }, [containerRef, isExpanded])

  if (!props.notifs) {
    return <></>
  }

  const unreadCount = props.notifs.filter(n => !n.isRead).length

  return (
    <div className={styles.notifs} ref={containerRef}>
      <div
        className={styles.trigger}
        onClick={() => {
          setIsExpanded(!isExpanded)
        }}
      >
        <BsBellFill />
        {unreadCount > 0 &&
          <div className={styles.trigger__badge}></div>
        }
      </div>
      {isExpanded &&
        <div className={styles.container}>
          {
            props.notifs.length
              ? props.notifs.map(n =>
                  <span
                    key={n._id}
                    onClick={() => {
                      if (!n.isRead) {
                        props.markNotificationsRead([n._id])
                      }
                      if (n.linkTo) {
                        history.push(n.linkTo)
                        setIsExpanded(false)
                      }
                    }}
                  >
                    <Notif
                      notif={n}
                    />
                  </span>
                )
              : <div className={styles.noNotifs}>No notifications</div>
          }
        </div>
      }
    </div>
  )
}

const mapStateToProps = ({ notifs }) => ({
  notifs: notifs.notifs,
})
const mapDispatchToProps = {
  getNotifications,
  markNotificationsRead
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications)  
