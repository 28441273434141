import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { IUser } from 'client/types'
import { PropagateLoader } from 'react-spinners'

interface AdminAuthenticationProps {
  history: any,
  isAdmin?: boolean,
  user: IUser
}

/**
 * Higher order component that requires authentication and redirects the user to the
 * login screen
 * @param {*} ComposedComponent - The component input
 */
export default (ComposedComponent: any) => {
  class AdminAuthentication extends React.Component<AdminAuthenticationProps & RouteComponentProps, {}> {
    render () {
      if (this.props.isAdmin !== undefined && this.props.isAdmin === false) {
        this.props.history.push('/')
      }
      return (this.props.isAdmin === true) ? <ComposedComponent {...this.props} /> : <PropagateLoader loading={true} />
    }
  }

  function mapStateToProps({ auth }) {
    return {
      isAdmin: auth.isAdmin,
      user: auth.user
    }
  }

  return connect(mapStateToProps)(withRouter(AdminAuthentication))
}
