import React, { useState } from 'react'
import { connect } from 'react-redux'
import { FiXCircle } from 'react-icons/fi'
import dayjs from 'dayjs'
import { IBet, IUser } from 'client/types'
import { cancelBet, CancelBetInfo } from 'client/modules/bets'
import { getUserProfile } from 'client/modules/profiles'
import styles from './styles.module.scss'

interface OpenBetItem {
  bet: IBet,
  cancelBet: (betInfo: CancelBetInfo) => any,
  getUserProfile: (userId: IUser['_id']) => any,
  user: IUser
}

const OpenBetItem: React.FC<OpenBetItem> = (props) => {
  const [didDelete, setDidDelete] = useState(false)

  if (didDelete) {
    return <></>
  }

  return (
    <div className={styles.betItem}>
      <div>{props.bet.displayName}</div>
      <div>${props.bet.opposingPayout.toFixed(2)}</div>
      <div>${props.bet.payout.toFixed(2)}</div>
      <div>
        <i>{dayjs(props.bet.firstEventStartTime).fromNow(true)}</i>
      </div>
      <div>
        <FiXCircle
          className={styles.betItem__delete}
          onClick={async () => {
            props.cancelBet({ betId: props.bet._id })
            setDidDelete(true)
            props.getUserProfile(props.user._id)
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user
})
const mapDispatchToProps = {
  cancelBet,
  getUserProfile
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpenBetItem)
