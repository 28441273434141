// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@media screen and (min-width: 1300px){.notifs_2K29tC{position:relative}}.trigger_30Xqos{cursor:pointer;position:relative;margin-right:10px;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;padding:10px;border-radius:20px;background-color:transparent;transition:background-color 0.25s ease}.trigger_30Xqos:hover{background-color:#eee}.trigger_30Xqos svg{font-size:20px}.trigger__badge_1B8GAG{position:absolute;top:11px;right:11px;width:8px;height:8px;border-radius:50px;background:#d42424}.container_35sVUI{padding:10px 0;position:absolute;z-index:10;width:90vw;max-width:500px;top:60px;right:10px;left:unset;background:#fff;box-shadow:0 12px 28px 0 rgba(0,0,0,0.2),0 2px 4px 0 rgba(0,0,0,0.1),inset 0 0 0 1px rgba(255,255,255,0.5);border-radius:8px;overflow:hidden;max-height:75vh;overflow-y:auto}@media screen and (min-width: 1300px){.container_35sVUI{width:400px;top:36px;left:-180px;right:unset}}.noNotifs_3BtipE{font-size:12px;padding:30px 0;text-align:center;color:#777}.notif_3lnXM_{padding:8px 30px 8px 15px;position:relative;cursor:pointer;transition:background-color 0.25s ease;background-color:#fff}.notif_3lnXM_:hover{background-color:#eee}.notif__footer_3LcEyi{color:#888;font-size:12px}.notif__status_1oB-_A{position:absolute;right:15px;top:50%;transform:translateY(-50%);width:10px;height:10px;background:transparent;border-radius:30px}.notif__status__unread_3SEF7w{background:#e4a820}\n", ""]);
// Exports
exports.locals = {
	"notifs": "notifs_2K29tC",
	"trigger": "trigger_30Xqos",
	"trigger__badge": "trigger__badge_1B8GAG",
	"container": "container_35sVUI",
	"noNotifs": "noNotifs_3BtipE",
	"notif": "notif_3lnXM_",
	"notif__footer": "notif__footer_3LcEyi",
	"notif__status": "notif__status_1oB-_A",
	"notif__status__unread": "notif__status__unread_3SEF7w"
};
module.exports = exports;
