// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".select_3go7_c{width:100%;padding:11px 5px;margin-right:20px;border:solid 1px #ccc;border-radius:10px;background:#eee;border:2px solid #ddd;font-size:18px;margin:6px 0 15px}\n", ""]);
// Exports
exports.locals = {
	"select": "select_3go7_c"
};
module.exports = exports;
