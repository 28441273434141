import React from 'react'
import { PropagateLoader } from 'react-spinners'

interface Props {
  isLoading: boolean
}

const ActivityMonitor: React.FC<Props> = (props: Props) => {
  return (
    (props.isLoading)
      ? (
        <PropagateLoader loading={props.isLoading} />
      )
      : (<React.Fragment />)
  )
}
export default ActivityMonitor
