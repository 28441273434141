// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".activityContainer_3Fa3ef{max-width:600px;margin:auto;padding:0 5px 50px}.filters_H7Lr-Y{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between;padding:15px 0 30px}.filters_H7Lr-Y>div:last-of-type{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:end;align-items:flex-end}.filters__search_3MZkf2[type=text]{background:#fff;border:none;border-radius:0;border-bottom:solid 1px #ccc;font-size:14px;line-height:28px;width:150px}@media screen and (min-width: 768px){.filters__search_3MZkf2[type=text]{width:200px}}.filters__search_3MZkf2[type=text]:focus{outline:none;border-color:#444}.filters__sportSelect_35-lhC{margin-right:20px;border:solid 1px #ccc;border-radius:3px;padding:5px 3px}.filters__sportSelect_35-lhC:focus{outline:none;border-color:#444}.filters__parlayToggle_15JeG4{margin-top:10px;font-size:12px;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center}.filters__parlayToggle_15JeG4 input{margin-left:5px}.noOpenBets_2Jjx8q{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;padding:100px 0 20px}.noOpenBets__title_2AQMp9{font-size:20px;color:#888;margin-bottom:20px}.noOpenBets_2Jjx8q button{font-size:14px;padding:15px}\n", ""]);
// Exports
exports.locals = {
	"activityContainer": "activityContainer_3Fa3ef",
	"filters": "filters_H7Lr-Y",
	"filters__search": "filters__search_3MZkf2",
	"filters__sportSelect": "filters__sportSelect_35-lhC",
	"filters__parlayToggle": "filters__parlayToggle_15JeG4",
	"noOpenBets": "noOpenBets_2Jjx8q",
	"noOpenBets__title": "noOpenBets__title_2AQMp9"
};
module.exports = exports;
