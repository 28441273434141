import React from 'react'
import { connect } from 'react-redux'
import { IBet, IUser } from 'client/types'
import LockedBet from './LockedBet'
import styles from './styles.module.scss'

const LockedBetGroup: React.FC<{ bets: Array<IBet>, user: IUser }> = (props) => {
  const totalRisk = props.bets.reduce((total, bet) => {
    const isCreator = bet.createdBy._id === props.user._id
    if (isCreator) {
      return total + bet.opposingPayout
    } else {
      return total + bet.payout
    }
  }, 0)

  const totalWin = props.bets.reduce((total, bet) => {
    const isCreator = bet.createdBy._id === props.user._id
    if (isCreator) {
      return total + bet.payout
    } else {
      return total + bet.opposingPayout
    }
  }, 0)

  return (
    <div className={styles.betGroup}>
      <div className={styles.betGroup__head}>
        <div>Pick</div>
        <div>Risk</div>
        <div>To Win</div>
        <div>Against</div>
        <div>Started</div>
      </div>
      <div className={styles.betGroup__body}>
        {props.bets.map(bet =>
          <LockedBet bet={bet} key={bet._id} />
        )}
      </div>
      <div className={styles.betGroup__footer}>
        <div><strong>Total:</strong></div>
        <div>${totalRisk.toFixed(2)}</div>
        <div>${totalWin.toFixed(2)}</div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user
})

export default connect(
  mapStateToProps,
)(LockedBetGroup)
