import React, { useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { IUser } from 'client/types'

interface AuthenticationProps {
  history: any,
  authenticated?: boolean,
  user: IUser
}

/**
 * Higher order component that requires authentication and redirects the user to the
 * login screen
 * @param {*} ComposedComponent - The component input
 */
export default (ComposedComponent: any) => {
  const Authentication = (props) => {
    useEffect(() => {
      if (props.didRepullUser && (!props.authenticated)) {
        props.history.push('/login')
      }
    }, [props.didRepullUser])

    return <ComposedComponent {...props} />
  }

  const mapStateToProps = ({ auth }) => {
    return {
      authenticated: auth.authenticated,
      user: auth.user,
      didRepullUser: auth.didRepullUser
    }
  }

  return connect(mapStateToProps)(withRouter(Authentication))
}
