// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".betGroup_3JQCtf{margin-top:20px}.betGroup__head_142HVy{font-weight:bold;margin-bottom:10px}.betGroup__footer_38LQlB{margin-top:30px}.betGroup__footer_38LQlB>div:nth-child(1){text-align:right;padding-right:30px !important}.betItem_3unc40{padding:10px 0}.betItem_3unc40 svg{cursor:pointer;color:#a44;margin-top:-1px;font-size:16px}@media screen and (min-width: 768px){.betItem_3unc40 svg{margin-top:1px}}.betGroup__head_142HVy,.betGroup__footer_38LQlB,.betItem_3unc40{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;font-size:10px}@media screen and (min-width: 768px){.betGroup__head_142HVy,.betGroup__footer_38LQlB,.betItem_3unc40{font-size:14px}}.betGroup__head_142HVy>div,.betGroup__footer_38LQlB>div,.betItem_3unc40>div{-ms-flex-negative:0;flex-shrink:0;-ms-flex-positive:0;flex-grow:0}.betGroup__head_142HVy>div:nth-child(1),.betGroup__footer_38LQlB>div:nth-child(1),.betItem_3unc40>div:nth-child(1){width:45%;padding-right:10px}.betGroup__head_142HVy>div:nth-child(2),.betGroup__footer_38LQlB>div:nth-child(2),.betItem_3unc40>div:nth-child(2){width:15%;padding-right:10px}.betGroup__head_142HVy>div:nth-child(3),.betGroup__footer_38LQlB>div:nth-child(3),.betItem_3unc40>div:nth-child(3){width:15%;padding-right:10px}.betGroup__head_142HVy>div:nth-child(4),.betGroup__footer_38LQlB>div:nth-child(4),.betItem_3unc40>div:nth-child(4){width:15%}.betGroup__head_142HVy>div:nth-child(5),.betGroup__footer_38LQlB>div:nth-child(5),.betItem_3unc40>div:nth-child(5){width:10%}\n", ""]);
// Exports
exports.locals = {
	"betGroup": "betGroup_3JQCtf",
	"betGroup__head": "betGroup__head_142HVy",
	"betGroup__footer": "betGroup__footer_38LQlB",
	"betItem": "betItem_3unc40"
};
module.exports = exports;
