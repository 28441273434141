import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FaCaretDown, FaCaretUp } from 'react-icons/fa'
import { FiXCircle } from 'react-icons/fi'
import { Button, Header, Icon, Modal, Popup } from 'semantic-ui-react'
import dayjs from 'dayjs'
import { addToBetslip, clearBetslip, toggleShowBetslip, createBet, CreateBetInfo, setIsSingleMode, isValidParlay } from '../../modules/bets'
import { IFixture, IBetSlipItem, IUser, IBetOption } from 'client/types'
import BetSlipItem from './betSlipItem'
import styles from './styles.module.scss'

interface Props {
  showBetslip: boolean,
  betSlip: Array<IBetSlipItem>,
  upcomingFixtures: {[sportId: string]: Array<IFixture>}
  user: IUser,
  addToBetslip: (fixtureId: string, specificBetId: string) => any,
  clearBetslip: () => any,
  toggleShowBetslip: () => any,
  createBet: ({ club, amount, betInfos }: CreateBetInfo) => any,
  isSingleMode: boolean,
  setIsSingleMode: (_: boolean) => any,
}
const BetSlip: React.FC<Props> = (props: Props) => {
  if (!props.showBetslip) {
    return <></>
  }

  const history = useHistory()
  const allFixtures: Array<IFixture> = []
  const [isExpanded, setIsExpanded] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false)
  const isSingle = props.isSingleMode
  
  Object.keys(props.upcomingFixtures).map((key: string, index: number) => {
    allFixtures.push(...props.upcomingFixtures[key])
  })
  const getSpecificBetInfo = (itemInBetslip: {fixtureId: string, specificBetId: string}): IBetOption | undefined => {
    let oddsItem: IBetOption
    let f: any
    f = allFixtures.find(f => f.odds.find(o => o.specificBetId === itemInBetslip.specificBetId))
    oddsItem = f.odds.find(o => o.specificBetId === itemInBetslip.specificBetId)
    return oddsItem
  }

  const [parlayAmount, setParlayAmount] = useState(20)
  const parlayOdds = props.betSlip.reduce((oddsSoFar, item) => {
    const betInfo: IBetOption = getSpecificBetInfo(item)
    return oddsSoFar * betInfo.odds
  }, 1)
  const parlayPayout = (parlayAmount * (parlayOdds - 1)).toFixed(2)
  const betInfos = props.betSlip.map((item: IBetSlipItem) => {
    return getSpecificBetInfo(item)
  })
  const displayNames = betInfos.map((betInfo) => {
    return betInfo.displayName
  })
  const parlayDisplayName = `Parlay: ${displayNames.join(', ')}`
  const parlayOpposingDisplayName = `Fade: ${displayNames.join(', ')}`

  const isParlayAllowed = isValidParlay(betInfos)

  const handleSubmitParlay = async () => {
    const newBetPromise = props.createBet({
      club: props.user.clubs[0],
      amount: parlayAmount,
      betInfos,
      parlayDisplayName,
      parlayOpposingDisplayName,
      parlayOdds,
    })

    try {
      await newBetPromise
    } catch (err) {
      // TODO: failure message
    }

    props.clearBetslip()
  }

  const handleSubmitSingle = async () => {
    const newBetPromises = props.betSlip.map((item: IBetSlipItem) => {
      const betOption: IBetOption = getSpecificBetInfo(item)

      return props.createBet({
        club: props.user.clubs[0],
        amount: item.amount,
        betInfos: [betOption]
      })
    })

    try {
      await Promise.all(newBetPromises)
    } catch (err) {
      // TODO: failure message
    }

    props.clearBetslip()
  }

  const handleSubmit = () => {
    if (isSingle) {
      return handleSubmitSingle()
    } else {
      return handleSubmitParlay()
    }
  }

  const confirmModal = () => {
    const [didConfirm, setDidConfirm] = useState(false)
    let receiptContent

    if (isSingle) {
      receiptContent = props.betSlip.map((item: IBetSlipItem, index: number) => {
        const specificBetInfo: IBetOption = getSpecificBetInfo(item)
        const payout = (item.amount * (specificBetInfo.odds - 1)).toFixed(2)
        return (
          <div key={specificBetInfo.specificBetId} className={styles.receiptItem}>
            <div>{dayjs(specificBetInfo.eventStartTime).format('M/D  h:mm A')}</div>
            <div>{specificBetInfo.displayName}</div>
            <div>${item.amount} to win ${payout}</div>
          </div>
        )
      })
    } else {
      receiptContent = (
        <div className={styles.receiptItem}>
          <div></div>
          <div>{parlayDisplayName}</div>
          <div>${parlayAmount} to win ${parlayPayout}</div>
        </div>
      )
    }

    return (
      <Modal
        basic
        onClose={() => {
          setOpenConfirmModal(false)

          if (didConfirm) {
            props.toggleShowBetslip()
          }
        }}
        onOpen={() => {
          setDidConfirm(false)
          setOpenConfirmModal(true)
        }}
        open={openConfirmModal}
        size='small'
        trigger={<Button color="blue">Open Bet Offer Confirmation</Button>}
      >
        <Header icon>
          <Icon name='clipboard check' />
          Bet Offer Confirmation
        </Header>
        <Modal.Content>
          {didConfirm
            ?
              <p style={{ textAlign: 'center' }}>Your bet offer has been posted to the Wishbone Community!</p>
            :
              <>
                <div style={{ margin: '0 0 1em' }}>
                  {receiptContent}
                </div>
                <p style={{ textAlign: 'center', marginTop: '30px' }}>
                  <span>Press below to post your bet offer to the Wishbone Community</span>
                  <Popup
                    trigger={<Icon circular name='question circle' />}
                    content='Once you post your wager, it can be locked by another opponent. Settle up after the wager ends.'
                    inverted
                  />
                </p>
              </>
          }
        </Modal.Content>
        <Modal.Actions>
          <Button basic color='red' inverted onClick={() => {
            setOpenConfirmModal(false)

            if (didConfirm) {
              props.toggleShowBetslip()
            }
          }}>
            <Icon name='remove' /> {didConfirm ? 'Close' : 'Cancel'}
          </Button>
          <Button
            color='green'
            inverted
            onClick={() => {
              if (didConfirm) {
                history.push('/my-bets')
                setOpenConfirmModal(false)
                props.toggleShowBetslip()
              } else {
                setDidConfirm(true)
                handleSubmit()
              }
            }
          }>
            {!didConfirm &&
              <Icon name='checkmark' />
            }
            {didConfirm ? 'Go to My Bets' : 'Confirm'}
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

  const singleTab = () => {
    return props.betSlip.map((item: IBetSlipItem, index: number) => {
      const specificBetInfo: IBetOption = getSpecificBetInfo(item)

      return (
        <BetSlipItem
          key={index}
          betSlipItem={item}
          specificBetInfo={specificBetInfo}
          betDisplayName={specificBetInfo.displayName}
        />
      )
    })
  }

  const renderSummary = () => {
    const numBets = props.betSlip.length
    const totalRisk = isSingle
      ? props.betSlip.reduce((total, slipItem) => {
          return total + slipItem.amount
        }, 0)
      : parlayAmount
    const possibleWinnings = isSingle
      ? props.betSlip.reduce((total, slipItem) => {
          const specificBetInfo: IBetOption = getSpecificBetInfo(slipItem)
          const payout = slipItem.amount * (specificBetInfo.odds - 1)
          return total + payout
        }, 0).toFixed(2)
      : parlayPayout

    return (
      <div className={styles.summary}>
        <div className={styles.summary__item}>
          <div>{isSingle ? 'Number of Bets' : 'Parlay Size'}</div>
          <div>{numBets}</div>
        </div>
        <div className={styles.summary__item}>
          <div>Total Risk</div>
          <div>${totalRisk}</div>
        </div>
        <div className={styles.summary__item}>
          <div>Possible Winnings</div>
          <div>${possibleWinnings}</div>
        </div>
      </div>
    )
  }

  const parlayTab = () => {
    const parlayItems = props.betSlip.map((item: IBetSlipItem, index: number) => {
      const betInfo: IBetOption = getSpecificBetInfo(item)
      return (
        <div style={{ margin: '0 0 8px' }}>
          <div className={styles.betslipItem__title}>
            {betInfo.displayName}
          </div>
          <div className={styles.betslipItem__subtitle}>
            {dayjs(betInfo.eventStartTime).format('M/D h:mm A')}
          </div>
        </div>
      )
    })

    return (
      <div className={styles.betslipItem}>
        {parlayItems}
        <div className={styles.betslipItem__money}>
          <div className={styles.moneyInput}>
            <span>Risk</span>
            <div className={styles.moneyInput__label}>$</div>
            <input
              type={'text'}
              value={parlayAmount}
              onChange={(e: any) => {
                const value = parseFloat(e.target.value)
                if (value < 0 || isNaN(value)) {
                  setParlayAmount(0)
                } else {
                  setParlayAmount(value)
                }
              }}
            />
          </div>
          <div className={styles.moneyInput}>
            <span>To Win</span>
            <div className={styles.moneyInput__label}>$</div>
            <input
              type={'text'}
              disabled={true}
              value={parlayPayout}
            />
          </div>
        </div>
        <div
          className={styles.betslipItem__delete}
          onClick={async () => {
            props.clearBetslip()
            props.toggleShowBetslip()
          }}
        ><FiXCircle/></div>
      </div>
    )
  }

  return (
    <div className={`${styles.betslip} ${isExpanded ? styles.betslip__expanded : ''}`}>
      <div onClick={() => setIsExpanded(!isExpanded)} className={styles.betslip__header}>
        <span>Bet Offer Slip ({props.betSlip.length})</span>
        {isExpanded
          ? <FaCaretDown />
          : <FaCaretUp />
        }
      </div>
      <div className={styles.typeToggler}>
        <div
          className={`${styles.typeToggler__option} ${isSingle ? styles.typeToggler__option__active : ''}`}
          onClick={() => props.setIsSingleMode(true)}
        >Single</div>
        <div
          className={`${styles.typeToggler__option} ${isParlayAllowed ? '' : styles.typeToggler__option__disabled} ${isSingle ? '' : styles.typeToggler__option__active}`}
          onClick={() => {
            if (isParlayAllowed) {
              props.setIsSingleMode(false)
            }
          }}
        >Parlay</div>
      </div>
      <div className={styles.betslip__top}>        
        {isSingle
          ? singleTab()
          : parlayTab()
        }
      </div>

      <div className={styles.betslip__bottom}>
        {renderSummary()}
        {confirmModal()}
      </div>
    </div>
  )
}

const mapStateToProps = ({ bets, bet365, auth }) => ({
  showBetslip: bets.showBetslip,
  betSlip: bets.betSlip,
  upcomingFixtures: bet365.upcomingFixtures,
  user: auth.user,
  isSingleMode: bets.isSingleMode
})
const mapDispatchToProps = {
  addToBetslip,
  clearBetslip,
  toggleShowBetslip,
  createBet,
  setIsSingleMode
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BetSlip)
