import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { NavLink, Route, Switch, useLocation, useHistory } from 'react-router-dom'
import { PropagateLoader } from 'react-spinners'
import { Tab } from 'semantic-ui-react'
import { chain } from 'lodash'
import { getUserProfile } from '../../modules/profiles'
import { IUser, IUserProfile } from 'client/types'
import Avatar from 'client/components/Avatar'
import OpenBets from './OpenBets'
import LockedBets from './LockedBets'
import UnsettledBets from './UnsettledBets'
import SettledBets from './SettledBets'
import styles from './styles.module.scss'

interface Props {
  currentProfile: IUserProfile,
  getUserProfile: (userId: IUser['_id']) => any,
  user: IUser
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Profile: React.FC<Props> = (props: Props) => {
  const [showSettled, setShowSettled] = useState(false)
  const query = useQuery()
  const history = useHistory()

  useEffect(() => {
    if (props.user) {
      props.getUserProfile(props.user._id)
    }
  }, [])

  useEffect(() => {
    if (query.get('tab') === 'settled') {
      setShowSettled(true)
    }
  }, [])

  const isLoading: boolean = props.currentProfile === undefined

  const openBets = !isLoading && props.currentProfile.openBets.length
    ? chain(props.currentProfile.openBets)
        .sortBy(bet => {
          return new Date(bet.firstEventStartTime)
        })
        .value()
    : []

  const lockedBets = !isLoading && props.currentProfile.acceptedBets.length
    ? chain(props.currentProfile.acceptedBets)
        .sortBy(bet => {
          return new Date(bet.firstEventStartTime)
        })
        .value()
    : []

  const unsettledBets = !isLoading && props.currentProfile.unsettledBets.length
    ? chain(props.currentProfile.unsettledBets)
        .sortBy(bet => {
          return new Date(bet.lastEventStartTime)
        })
        .value()
    : []

  const settledBets = !isLoading && props.currentProfile.settledBets.length
    ? chain(props.currentProfile.settledBets)
        .sortBy(bet => {
          return new Date(bet.lastEventStartTime)
        })
        .value()
    : []

  const profilePanes = [
    {
      menuItem: {
        as: NavLink,
        id: 'profileTab1',
        content: 'Open Offers',
        to: '/my-bets',
        exact: true,
        key: 'offers'
      },
      pane: (
        <Route
          path='/my-bets'
          exact
          render={() => (
            <Tab.Pane attached={false}>
              {openBets.length
                ? <OpenBets bets={openBets} />
                : <div className={styles.profile__noBets}>No open offers</div>
              }
            </Tab.Pane>
          )}
        />
      )
    },
    {
      menuItem: {
        as: NavLink,
        id: 'profileTab2',
        content: 'Locked',
        to: `/my-bets/locked`,
        exact: true,
        key: 'lockedBets'
      },
      pane: (
        <Route
          path='/my-bets/locked'
          exact
          render={() => (
            <Tab.Pane attached={false}>
              {lockedBets.length
                ? <LockedBets bets={lockedBets} />
                : <div className={styles.profile__noBets}>No locked bets</div>
              }
            </Tab.Pane>
          )}
        />
      )
    },
    {
      menuItem: {
        as: NavLink,
        id: 'profileTab3',
        content: 'Closed',
        to: `/my-bets/closed`,
        exact: true,
        key: 'closedBets'
      },
      pane: (
        <Route
          path='/my-bets/closed'
          exact
          render={() => {
            return (
              <Tab.Pane attached={false}>
                <div className={styles.tabber}>
                  <div
                    className={`${styles.tabber__tab} ${showSettled ? '' : styles.tabber__tab__selected}`}
                    onClick={() => {
                      setShowSettled(false)
                      history.push({
                        search: '?tab=pending'
                      })
                    }}
                  >Pending</div>
                  <div
                    className={`${styles.tabber__tab} ${showSettled ? styles.tabber__tab__selected : ''}`}
                    onClick={() => {
                      setShowSettled(true)
                      history.push({
                        search: '?tab=settled'
                      })
                    }}
                  >Settled</div>
                </div>
                {showSettled
                  ?
                    settledBets.length
                      ? <SettledBets bets={settledBets} />
                      : <div className={styles.profile__noBets}>No settled bets</div>
                  :
                    unsettledBets.length
                      ? <UnsettledBets bets={unsettledBets} />
                      : <div className={styles.profile__noBets}>No pending bets</div>
                }
              </Tab.Pane>
            )
          }}
        />
      )
    }
  ]

  return (
    <div className={styles.activityContainer}>
      {isLoading && (
        <div className='loading-container'>
          <PropagateLoader
            loading={isLoading}
          />
        </div>
      )}
      {!isLoading && (
        <div className={styles.profile}>
          <div className={styles.profile__head}>
            <Avatar
              user={props.currentProfile}
            />
            <div className={styles.profile__title}>
              {props.currentProfile.firstName} {props.currentProfile.lastName}
            </div>
            <div className={styles.profile__subtitle}>
              {props.currentProfile.paymentMethod}: {props.currentProfile.paymentHandle}
            </div>
          </div>
          <div className={styles.profile__body}>
            <Switch>
              <Tab
                menu={{ secondary: true, pointing: true }}
                panes={profilePanes}
                onTabChange={() => props.getUserProfile(props.user._id)}
                activeIndex={-1}
                renderActiveOnly={false}
              />
            </Switch>
          </div>
        </div>
      )}
    </div>
  )
}
const mapStateToProps = ({ profiles, auth }) => ({
  currentProfile: profiles.currentProfile,
  user: auth.user
})
const mapDispatchToProps = {
  getUserProfile
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile)
