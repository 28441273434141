import React, { useEffect, useState } from 'react'
import { Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { PropagateLoader } from 'react-spinners'
import { chain } from 'lodash'
import { getBetsByStatus } from 'client/modules/bets'
import { BetStatus, IBet } from 'client/types'
import { leagueOptions } from 'client/utils/bet365mappings'
import OpenBetGroup from '../../components/OpenBetGroup'
import styles from './styles.module.scss'

interface Props {
  openBets?: Array<IBet>,
  getBetsByStatus: (status: BetStatus) => any
}

const OpenBets: React.FC<Props> = (props: Props) => {
  useEffect(() => {
    props.getBetsByStatus(BetStatus.OPEN)
  }, [])

  const [searchQuery, setSearchQuery] = useState('')
  const [selectedLeague, setSelectedLeague] = useState('All')
  const [highlightParlayGroup, setHighlightParlayGroup] = useState(false)

  const isLoading: boolean = props.openBets === undefined

  const getBetGroups = () => {
    if (props.openBets === undefined) {
      return []
    }

    return chain(props.openBets)
      .map(b => b.betInfos)
      .flatten()
      .map(bi => bi.fixtureId)
      .uniq()
      .map(fid => {
        const betsForThisFixture = props.openBets.filter(b =>
          b.betInfos.some(bi => bi.fixtureId === fid)
        )
        const fixtureInfo = betsForThisFixture[0].betInfos.find(bi => bi.fixtureId === fid)
        return {
          fixtureInfo,
          bets: betsForThisFixture
        }
      })
      .sortBy((betGroup) => {
        return new Date(betGroup.fixtureInfo.eventStartTime)
      })
      .filter((betGroup) => {
        // Sport filter
        if (selectedLeague === 'All') {
          return true
        }

        const { leagueId } = betGroup.fixtureInfo
        return selectedLeague === leagueId
      })
      .filter((betGroup) => {
        // Search filter
        if (searchQuery.length < 2) {
          return true
        }

        const query = searchQuery.toLowerCase()
        const { fixtureHomeName, fixtureAwayName } = betGroup.fixtureInfo
        if (fixtureHomeName.toLowerCase().indexOf(query) >= 0 || fixtureAwayName.toLowerCase().indexOf(query) >= 0) {
          return true
        }

        return false
      })
      .value()
  }

  const parlayGroup = () => {
    if (props.openBets === undefined) {
      return undefined
    }

    const parlays = props.openBets.filter(b => b.isParlay)

    if (!parlays.length) {
      return undefined
    }
      
    return (
      <div className='bet-spacer' key='parlayzzz'>
        <OpenBetGroup bets={parlays} customTitle={'Parlays'} />
      </div>
    )
  }

  const showParlayGroup = highlightParlayGroup || (!searchQuery && selectedLeague === 'All')

  const groupedBets = getBetGroups()

  return (
    <div className={styles.activityContainer}>
      <div className={styles.filters}>
        <div>
          <select
            className={styles.filters__sportSelect}
            name='sport-select'
            id='sport-select'
            onChange={(event) => {
              const option = leagueOptions.find(option => option.name === event.target.value)
              setSelectedLeague(option.leagueId)
            }}
          >
            {leagueOptions.map((item) =>
              <option key={item.name} value={item.name}>{item.name}</option>
            )}
          </select>
          <Button icon='refresh' secondary size='mini' onClick={() => props.getBetsByStatus(BetStatus.OPEN)} />
        </div>
        <div>
          <input
            className={styles.filters__search}
            type='text'
            placeholder='Events'
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.target.value)}
          />
          <label className={styles.filters__parlayToggle}>
            View Parlay Offers
            <input
              type='checkbox'
              checked={highlightParlayGroup}
              onChange={(event) => setHighlightParlayGroup(event.target.checked)}
            />
          </label>
        </div>
      </div>
      {isLoading && (
        <div className='loading-container'>
          <PropagateLoader
            loading={isLoading}
          />
        </div>
      )}
      {!isLoading && groupedBets.length > 0 && !highlightParlayGroup && (
        groupedBets.map((betGroup) =>
          <div className='bet-spacer' key={betGroup.fixtureInfo.fixtureId}>
            <OpenBetGroup bets={betGroup.bets} fixtureInfo={betGroup.fixtureInfo} />
          </div>
        )
      )}
      {showParlayGroup && parlayGroup()}
      {!isLoading && !groupedBets.length && (
        <div className={styles.noOpenBets}>
          <div className={styles.noOpenBets__title}>No results</div>
        </div>
      )}
    </div>
  )
}
const mapStateToProps = ({ bets }) => ({
  openBets: bets.openBets
})
const mapDispatchToProps = {
  getBetsByStatus
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpenBets)
