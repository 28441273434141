import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import { INotification } from 'client/types'
import styles from './styles.module.scss'

interface Props {
  notif: INotification,
}

const Notif: React.FC<Props> = (props) => {
  return (
    <div className={styles.notif}>
      <div className={styles.notif__name}>{props.notif.displayName}</div>
      <div className={styles.notif__footer}>{dayjs(props.notif.createdAt).fromNow()}</div>
      <div className={`${styles.notif__status} ${props.notif.isRead ? '' : styles.notif__status__unread}`}></div>
    </div>
  )
}

const mapDispatchToProps = {

}
export default connect(
  undefined,
  mapDispatchToProps
)(Notif)  
