// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_SFVYlG{max-width:600px;margin:auto;padding:0 5px 50px}.container_SFVYlG .membersList_2GB0z7 .header_EVjVRz{display:-ms-grid;display:grid;-ms-grid-columns:25% 25%;grid-template-columns:25% 25%;border-bottom:1px solid black;padding-bottom:10px}.container_SFVYlG .membersList_2GB0z7 .tableBody_13MEVO{width:100%}.application_2jYZ_s{padding:5px 0;width:100%;display:-ms-grid;display:grid;-ms-grid-columns:25% 25% 25% 25%;grid-template-columns:25% 25% 25% 25%}\n", ""]);
// Exports
exports.locals = {
	"container": "container_SFVYlG",
	"membersList": "membersList_2GB0z7",
	"header": "header_EVjVRz",
	"tableBody": "tableBody_13MEVO",
	"application": "application_2jYZ_s"
};
module.exports = exports;
