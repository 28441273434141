import React from 'react'

const NotFound: React.FunctionComponent = () => {
  return (
    <div className='container'>
      <h1>Page not found</h1>
    </div>
  )
}

export default NotFound
