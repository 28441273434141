import React, { useState } from 'react'
import dayjs from 'dayjs'
import { chain } from 'lodash'
import { HiPlusCircle } from 'react-icons/hi'
import { IBet, IBetOption } from '../../types'
import SportIcon from '../SportIcon'
import OpenBetItem from './openBetItem'
import styles from './styles.module.scss'

const sortOptions = ['Newest', 'Oldest', 'Opponent amount', 'My amount', 'Bet type']

const OpenBetGroup: React.FC<{ bets: Array<IBet>, fixtureInfo?: IBetOption, customTitle?: string }> = (props) => {
  const [sort, setSort] = useState('Newest')

  // TODO: sorting
  const orderedBets = chain(props.bets)
    .sortBy(b => {
      switch (sort) {
        case 'Oldest':
          return new Date(b.createdAt)
        case 'Newest':
          return new Date(b.createdAt).getMilliseconds() * -1
        case 'Opponent amount':
          return -b.opposingPayout
        case 'My amount':
          return -b.payout
        case 'Bet type':
          if (b.isParlay) {
            return 'ZParlay' // so it's at the bottom :>)
          } else {
            return b.betInfos[0].betType
          }
        default:
          return new Date(b.createdAt).getMilliseconds() * -1
      }
    })
    .value()

  const choppedBets = orderedBets.slice(0, 3)
  const showExpander = orderedBets.length > 3

  const [showAll, setShowAll] = useState(false)

  return (
    <div className={styles.betGroup}>
      <div className={styles.betGroup__head}>
        <div className={styles.betGroup__headLeft}>
          {
            props.customTitle
              ? <span>{props.customTitle}</span>
              : <>
                  <SportIcon sportId={props.fixtureInfo.sportId}/>
                  <span className={styles.betGroup__date}>{dayjs(props.fixtureInfo.eventStartTime).format('M/D h:mm A')}</span>
                  <span>{props.fixtureInfo.fixtureHomeName} @ {props.fixtureInfo.fixtureAwayName}</span>
                </>
          }
        </div>
        <div className={styles.betGroup__headRight}>
          <select
            className={styles.cleanSelect}
            dir="rtl"
            onChange={(event) => {
              setSort(event.target.value)
            }}
          >
            {sortOptions.map(sortOp => 
              <option key={sortOp} value={sortOp}>{sortOp}</option>  
            )}
          </select>
        </div>
      </div>
      <div className={styles.betGroup__body}>
        <div className={styles.betGroup__splitter}>
          <div>Opponent</div>
          <div>Me</div>
        </div>
        <div className={styles.tableHeader}>
          <div>User</div>
          <div>Pick</div>
          <div>Amount</div>
          <div>Pick</div>
          <div>Amount</div>
          <div> </div>
        </div>
        {(showAll ? orderedBets : choppedBets).map((item: IBet) =>
          <OpenBetItem key={item._id} bet={item} />
        )}
        {showExpander && !showAll &&
          <div className={styles.betGroup__footer}>
            <HiPlusCircle onClick={() => setShowAll(true)} />
          </div>
        }
      </div>
    </div>
  )
}

export default OpenBetGroup
