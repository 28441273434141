// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".avatar_1r6rgu{height:36px;width:36px;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;background:#fff;border:solid 1px #000;border-radius:20px;margin:0}.avatar__letters_3zO_fC{color:#000;font-size:16px}\n", ""]);
// Exports
exports.locals = {
	"avatar": "avatar_1r6rgu",
	"avatar__letters": "avatar__letters_3zO_fC"
};
module.exports = exports;
