import React from 'react'
import { find } from 'lodash'
import dayjs from 'dayjs'
import { IFixture, IBetSlipItem, BetType, IBetOption, BetSide } from 'client/types'
import styles from './styles.module.scss'
import { connect } from 'react-redux'
import { addToBetslip, removeFromBetslip, toggleShowBetslip, isValidParlay } from 'client/modules/bets'
import SportIcon from 'client/components/SportIcon'
interface FixturePreviewProps {
  fixture: IFixture,
  showBetslip: boolean,
  betSlip: Array<IBetSlipItem>,
  upcomingFixtures: {[sportId: string]: Array<IFixture>},
  addToBetslip: (fixtureId: string, specificBetId: string) => any,
  removeFromBetslip: (fixtureId: string, specificBetId: string) => any,
  toggleShowBetslip: () => any,
  isSingleMode: boolean
}

const FixturePreview: React.FunctionComponent<FixturePreviewProps> = (props) => {
  const oddsRaw: Array<IBetOption> = props.fixture.odds
  const odds = [
    find(oddsRaw, { betSide: BetSide.ONE, betType: BetType.SPREAD }) || undefined,
    find(oddsRaw, { betSide: BetSide.ONE, betType: BetType.OVERUNDER }) || undefined,
    find(oddsRaw, { betSide: BetSide.ONE, betType: BetType.MONEYLINE }) || undefined,
    find(oddsRaw, { betSide: BetSide.TWO, betType: BetType.SPREAD }) || undefined,
    find(oddsRaw, { betSide: BetSide.TWO, betType: BetType.OVERUNDER }) || undefined,
    find(oddsRaw, { betSide: BetSide.TWO, betType: BetType.MONEYLINE }) || undefined,
  ]

  const emptyOdds = { odds: '-' }

  const betOptions: any = {
    side1Spread: find(odds, { betSide: BetSide.ONE, betType: BetType.SPREAD }) || emptyOdds,
    side2Spread: find(odds, { betSide: BetSide.TWO, betType: BetType.SPREAD }) || emptyOdds,
    side1Total: find(odds, { betSide: BetSide.ONE, betType: BetType.OVERUNDER }) || emptyOdds,
    side2Total: find(odds, { betSide: BetSide.TWO, betType: BetType.OVERUNDER }) || emptyOdds,
    side1Line: find(odds, { betSide: BetSide.ONE, betType: BetType.MONEYLINE }) || emptyOdds,
    side2Line: find(odds, { betSide: BetSide.TWO, betType: BetType.MONEYLINE }) || emptyOdds,
    drawLine: find(odds, { betSide: BetSide.DRAW, betType: BetType.MONEYLINE }) || emptyOdds
  }

  const allFixtures: Array<IFixture> = []
  Object.keys(props.upcomingFixtures).map((key: string, index: number) => {
    allFixtures.push(...props.upcomingFixtures[key])
  })

  const getSpecificBetInfo = (itemInBetslip: {fixtureId: string, specificBetId: string}): IBetOption | undefined => {
    let oddsItem: IBetOption
    let f: any
    f = allFixtures.find(f => f.odds.find(o => o.specificBetId === itemInBetslip.specificBetId))
    oddsItem = f.odds.find(o => o.specificBetId === itemInBetslip.specificBetId)
    return oddsItem
  }

  const betSlipInfos = props.betSlip.map((item: IBetSlipItem) => {
    return getSpecificBetInfo(item)
  })

  const isSoccer = props.fixture.sportId === '1'

  const getSpecificBetId = (oddsBoxId: number): string | undefined => {
    const box = odds[oddsBoxId] || undefined
    return box?.specificBetId
  }

  // return undefined if not in the betslip
  const getIndexOfBetInBetslip = (fixtureId: string, specificBetId: string): number | undefined => {
    if (props.betSlip === undefined) {
      return undefined
    }
    let foundIndex: number | undefined = undefined
    props.betSlip.find((item, index) => {
      if (item.fixtureId === fixtureId && item.specificBetId === specificBetId) {
        foundIndex = index
      }
    })
    return foundIndex
  }

  const handleClick = async (oddsBoxId: string | number) => {
    if (odds.length === 0) return undefined

    const fixtureId = props.fixture.fixtureId
    // @ts-ignore
    const specificBetId = getSpecificBetId(oddsBoxId)

    // don't allow a click event if the odds aren't set for some reason
    if (fixtureId === undefined || specificBetId === undefined) {
      return undefined
    }

    const indexInBetslip: number | undefined = getIndexOfBetInBetslip(fixtureId, specificBetId)
    if (indexInBetslip === undefined) {
      await props.addToBetslip(fixtureId, specificBetId)
    } else {
      if (props.betSlip.length === 1) {
        await props.toggleShowBetslip()
      }
      await props.removeFromBetslip(fixtureId, specificBetId)
    }
    if (!props.showBetslip) {
      await props.toggleShowBetslip()
    }
  }

  const renderBets = () => {
    const isSingle = props.isSingleMode
    const cell1Selected = getIndexOfBetInBetslip(props.fixture.fixtureId, getSpecificBetId(0)) !== undefined
    const cell1Disabled = !isSingle && !cell1Selected && !isValidParlay([...betSlipInfos, odds[0]])
    const cell2Selected = getIndexOfBetInBetslip(props.fixture.fixtureId, getSpecificBetId(2)) !== undefined
    const cell2Disabled = !isSingle && !cell2Selected && !isValidParlay([...betSlipInfos, odds[2]])
    const cell3Selected = getIndexOfBetInBetslip(props.fixture.fixtureId, getSpecificBetId(1)) !== undefined
    const cell3Disabled = !isSingle && !cell3Selected && !isValidParlay([...betSlipInfos, odds[1]])
    const cell4Selected = getIndexOfBetInBetslip(props.fixture.fixtureId, getSpecificBetId(3)) !== undefined
    const cell4Disabled = !isSingle && !cell4Selected && !isValidParlay([...betSlipInfos, odds[3]])
    const cell5Selected = getIndexOfBetInBetslip(props.fixture.fixtureId, getSpecificBetId(5)) !== undefined
    const cell5Disabled = !isSingle && !cell5Selected && !isValidParlay([...betSlipInfos, odds[5]])
    const cell6Selected = getIndexOfBetInBetslip(props.fixture.fixtureId, getSpecificBetId(4)) !== undefined
    const cell6Disabled = !isSingle && !cell6Selected && !isValidParlay([...betSlipInfos, odds[4]])

    return (
      <div className={styles.fixturePreview}>
        <div className={styles.fixturePreview__header}>
          <div className={styles.fixturePreview__headerLeft}>
            <SportIcon sportId={props.fixture.sportId}></SportIcon>
            <span>
              {dayjs.unix(parseInt(props.fixture.startTime)).format('M/D/YY ')}
              {dayjs.unix(parseInt(props.fixture.startTime)).format('h:mm A')}
            </span>
          </div>
          <div className={styles.fixturePreview__headerRight}>
            <span>Spread</span>
            <span>Money</span>
            <span>Total</span>
          </div>
        </div>
        <div className={styles.fixturePreview__body}>
          <div className={styles.fixturePreview__row}>
            <div className={styles.fixturePreview__col}>{props.fixture.home.name}</div>
            <div className={`${styles.fixturePreview__col} ${cell1Disabled ? styles.fixturePreview__col__disabled : ''} ${!isSoccer && getIndexOfBetInBetslip(props.fixture.fixtureId, getSpecificBetId(0)) !== undefined ? styles.fixturePreview__active : ''}`}
              onClick={async () => {
                if (cell1Disabled) {
                  return
                }

                if (!isSoccer) {
                  await handleClick(0)
                }
              }}
            >
              
              <span className={styles.highlight}>{betOptions.side1Spread.handicap || '--'}</span>
              <span>{betOptions.side1Spread.americanOdds ? `(${betOptions.side1Spread.americanOdds})` : ''}</span>
            </div>
            <div className={`${styles.fixturePreview__col} ${cell2Disabled ? styles.fixturePreview__col__disabled : ''} ${getIndexOfBetInBetslip(props.fixture.fixtureId, getSpecificBetId(!isSoccer ? 2 : 0)) !== undefined ? styles.fixturePreview__active : ''}`}
              onClick={async () => {
                if (cell2Disabled) {
                  return
                }

                if (!isSoccer) {
                  await handleClick(2)
                } else {
                  await handleClick(0)
                }
              }}
            >
              <span>{betOptions.side1Line.americanOdds || '--'}</span>
            </div>
            <div className={`${styles.fixturePreview__col} ${cell3Disabled ? styles.fixturePreview__col__disabled : ''} ${!isSoccer && getIndexOfBetInBetslip(props.fixture.fixtureId, getSpecificBetId(1)) !== undefined ? styles.fixturePreview__active : ''}`}
              onClick={async () => {
                if (cell3Disabled) {
                  return
                }

                if (!isSoccer) {
                  await handleClick(1)
                }
              }}
            >
              <span className={styles.highlight}>{betOptions.side1Total.handicap || '--'}</span>
              <span>{betOptions.side1Total.americanOdds ? `(${betOptions.side1Total.americanOdds})` : ''}</span>
            </div>
          </div>
          <div className={styles.fixturePreview__row}>
            <div className={styles.fixturePreview__col}>{props.fixture.away.name}</div>
            <div className={`${styles.fixturePreview__col} ${cell4Disabled ? styles.fixturePreview__col__disabled : ''} ${!isSoccer && getIndexOfBetInBetslip(props.fixture.fixtureId, getSpecificBetId(3)) !== undefined ? styles.fixturePreview__active : ''}`}
              onClick={async () => {
                if (cell4Disabled) {
                  return
                }

                if (!isSoccer) {
                  await handleClick(3)
                }
              }}
            >
              <span className={styles.highlight}>{betOptions.side2Spread.handicap || '--'}</span>
              <span>{betOptions.side2Spread.americanOdds ? `(${betOptions.side2Spread.americanOdds})` : ''}</span>
            </div>
            <div className={`${styles.fixturePreview__col} ${cell5Disabled ? styles.fixturePreview__col__disabled : ''} ${getIndexOfBetInBetslip(props.fixture.fixtureId, getSpecificBetId(!isSoccer ? 5 : 2)) !== undefined ? styles.fixturePreview__active : ''}`}
              onClick={async () => {
                if (cell5Disabled) {
                  return
                }

                if (!isSoccer) {
                  await handleClick(5)
                } else {
                  await handleClick(2)
                }
              }}
            >
              <span>{betOptions.side2Line.americanOdds || '--'}</span>
            </div>
            <div className={`${styles.fixturePreview__col} ${cell6Disabled ? styles.fixturePreview__col__disabled : ''} ${!isSoccer && getIndexOfBetInBetslip(props.fixture.fixtureId, getSpecificBetId(4)) !== undefined ? styles.fixturePreview__active : ''}`}
              onClick={async () => {
                if (cell6Disabled) {
                  return
                }

                if (!isSoccer) {
                  await handleClick(4)
                }
              }}
            >
              <span className={styles.highlight}>{betOptions.side2Total.handicap || '--'}</span>
              <span>{betOptions.side2Total.americanOdds ? `(${betOptions.side2Total.americanOdds})` : ''}</span>
            </div>
          </div>
          {props.fixture.sportId === '1' && (
            <div className={styles.fixturePreview__row}>
              <div className={styles.fixturePreview__col}>{'Draw'}</div>
              <div className={styles.fixturePreview__col}>
                <span />
                <span>(-)</span>
              </div>
              <div className={`${styles.fixturePreview__col} ${getIndexOfBetInBetslip(props.fixture.fixtureId, getSpecificBetId(1)) !== undefined ? styles.fixturePreview__active : ''}`}
                onClick={async () => await handleClick(1)}
              >
                <span>{betOptions.drawLine.americanOdds}</span>
              </div>
              <div className={styles.fixturePreview__col}>
                <span />
                <span>(-)</span>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  return renderBets()
}

const mapStateToProps = ({ bets, bet365 }) => ({
  showBetslip: bets.showBetslip,
  betSlip: bets.betSlip,
  upcomingFixtures: bet365.upcomingFixtures,
  isSingleMode: bets.isSingleMode
})
const mapDispatchToProps = {
  addToBetslip,
  removeFromBetslip,
  toggleShowBetslip
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FixturePreview)  
