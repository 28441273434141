import axios from 'axios'
import { Dispatch, Action } from 'redux'
import { IUser } from 'client/types'
import { API_URL } from 'client/utils/api'

// Types
export enum ProfileTypes {
  PROFILE_ERROR = 'profile/PROFILE_ERROR',
  CURRENT_PROFILE = 'profile/CURRENT_PROFILE'
}

interface ProfileAction extends Action {
  payload?: any
}

interface ProfileError {
  data: any
  status: number
  response: any
}

interface ProfileState {
  error?: ProfileError
  currentProfile?: IUser
}

// Reducer
const INITIAL_STATE: ProfileState = {
  error: undefined,
  currentProfile: undefined
}

export default function (state = INITIAL_STATE, action: ProfileAction) {
  switch (action.type) {
    case ProfileTypes.PROFILE_ERROR:
      return { ...state, error: action.payload }
    case ProfileTypes.CURRENT_PROFILE:
      return { ...state, currentProfile: action.payload }
    default:
      return state
  }
}

// Action creator to handle errors
export const errorHandler = (dispatch: Dispatch, error: ProfileError, type: ProfileTypes) => {
  let errorMessage = ''

  if (!error.data) {
    dispatch({
      type,
      payload: 'An error occurred'
    })
  }

  if (error.data.error) {
    errorMessage = error.data.error
  } else if (error.data) {
    errorMessage = error.data
  }

  dispatch({
    type,
    payload: errorMessage
  })
}

export const getUserProfile = (userId: IUser['_id']) => {
  return (dispatch: Dispatch) => {
    axios.get(`${API_URL}/user/${userId}/profile`)
      .then((response) => {
        dispatch({
          type: ProfileTypes.CURRENT_PROFILE,
          payload: response.data.profile
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, ProfileTypes.PROFILE_ERROR)
      })
  }
}

export const getUserFromId = async (userId: IUser['_id']) => {
  const req = await axios.get(`${API_URL}/user/${userId}/profile`)
  // console.log('got user profile', req.data.profile)
  return req.data.profile
}
