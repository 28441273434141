import React, { useState } from 'react'
import { connect } from 'react-redux'
import validator from 'validator'
import { triggerPasswordReset } from 'client/modules/auth'
import styles from './styles.module.scss'

interface TriggerResetProps {
  triggerPasswordReset: (email: string) => any
}

const TriggerReset: React.FC<TriggerResetProps> = (props) => {
  const [email, setEmail] = useState('')
  const [didSend, setDidSend] = useState(false)
  return (
    <div className='skinny-container'>
      {didSend &&
        <h3>We sent you an email with a link to get back into your account</h3>
      }
      {!didSend &&
        <>
          <h2>Trouble Logging In?</h2>
          <p>Enter your email and we'll send you a link to get back into your account</p>
          <input
            className={styles.input}
            placeholder='Email'
            type='email'
            onChange={(event) => setEmail(event.target.value)}
          />
          <button className='ui button fluid large blue' onClick={() => {
            if (!validator.isEmail(email)) return
            props.triggerPasswordReset(email)
            setDidSend(true)
          }}>Send Login Link</button>
        </>
      }
    </div>
  )
}

const mapDispatchToProps = {
  triggerPasswordReset
}

export default connect(
  undefined,
  mapDispatchToProps
)(TriggerReset)
