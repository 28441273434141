import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { ImCheckmark } from 'react-icons/im'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import { Button, Header, Icon, Modal, Popup } from 'semantic-ui-react'
import dayjs from 'dayjs'
import { IBet } from '../../types'
import { acceptBet, AcceptBetInfo } from 'client/modules/bets'
import styles from './styles.module.scss'

interface OpenBetItem {
  bet: IBet,
  acceptBet: (betInfo: AcceptBetInfo) => any,
}

const OpenBetItem: React.FC<OpenBetItem> = (props) => {
  const history = useHistory()
  const [showCheck, setShowCheck] = useState(false)
  const [didAccept, setDidAccept] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false)

  const confirmModal = () => {
    return (
      <Modal
        basic
        onClose={() => {
          setOpenConfirmModal(false)

          if (didAccept) {
            setShowCheck(true)
          }
        }}
        onOpen={() => {
          setDidAccept(false)
          setOpenConfirmModal(true)
        }}
        open={openConfirmModal}
        size='small'
        trigger={<FaArrowAltCircleRight className={styles.betItem__cta} />}
      >
        <Header icon>
          <Icon name={didAccept ? 'lock' : 'unlock'} />
          Lock Bet
        </Header>
        <Modal.Content>
          {didAccept
            ?
              <p style={{ textAlign: 'center' }}>Your bet has been locked with {props.bet.createdBy.firstName} {props.bet.createdBy.lastName}!</p>
            :
              <>
                <div style={{ margin: '0 0 1em' }}>
                  <div className={styles.receiptItem}>
                    <div>{dayjs(props.bet.firstEventStartTime).format('M/D h:mm A')}</div>
                    <div className={styles.receiptItem__table}>
                      <div>
                        <span>Opponent</span>
                        <span>Payment</span>
                      </div>
                      <div>
                        <span>{props.bet.createdBy.firstName} {props.bet.createdBy.lastName}</span>
                        {props.bet.createdBy.paymentMethod}
                      </div>
                    </div>
                    <div>
                      <div style={{ color: '#bbb' }}>Your Bet</div>
                      <div style={{ margin: '3px 0' }}>{props.bet.opposingDisplayName}</div>
                      <div>Risk ${props.bet.payout.toFixed(2)} to win ${props.bet.opposingPayout.toFixed(2)}</div>
                    </div>
                  </div>
                </div>
                <p style={{ textAlign: 'center', marginTop: '30px' }}>
                  <span>Press below to lock in your wager with {props.bet.createdBy.firstName} {props.bet.createdBy.lastName}</span>
                  <Popup
                    trigger={<Icon circular name='question circle' />}
                    content='Clicking the button below will lock in your wager with your opponent. Settle up after the wager ends.'
                    inverted
                  />
                </p>
              </>
          }
        </Modal.Content>
        <Modal.Actions>
          <Button basic color='red' inverted onClick={() => {
            setOpenConfirmModal(false)

            if (didAccept) {
              setShowCheck(true)
            }
          }}>
            <Icon name='remove' /> {didAccept ? 'Close' : 'Cancel'}
          </Button>
          <Button
            color='green'
            inverted
            onClick={() => {
              if (didAccept) {
                history.push('/my-bets/locked')
                setOpenConfirmModal(false)
              } else {
                props.acceptBet({ betId: props.bet._id })
                setDidAccept(true)
              }
            }
          }>
            {!didAccept &&
              <Icon name='checkmark' />
            }
            {didAccept ? 'Go to My Bets' : 'Lock Bet'}
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

  return (
    <div className={styles.betItem}>
      <div>
        {props.bet.createdBy.firstName[0].toUpperCase()}{props.bet.createdBy.lastName[0].toUpperCase()}
      </div>
      <div>{props.bet.displayName}</div>
      <div>${props.bet.opposingPayout.toFixed(2)}</div>
      <div>{props.bet.opposingDisplayName}</div>
      <div>${props.bet.payout.toFixed(2)}</div>
      <div className={styles.betItem__ctaContainer}>
        {showCheck
          ? <ImCheckmark className={styles.betItem__successMsg} />
          : confirmModal()
        }
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  acceptBet
}
export default connect(
  undefined,
  mapDispatchToProps
)(OpenBetItem)
