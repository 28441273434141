import React from 'react'
import styles from './styles.module.scss'

const Juice: React.FC<{}> = () => {
  return (
    <div className="container">
      <h2 style={{ textAlign: 'center', marginBottom: '30px' }}>What is Juice?</h2>
      <p>Whether or not you’re familiar with the term ‘juice’ (or ‘vig’), here’s a crash course on what it means, how we remove it, and how its removal benefits you.</p>
      <p className={styles.small}>‘Juice’ is a hedging element factored into the odds of any bet you take with a sportsbook. It’s how the “house” (your bookie) is guaranteed to profit no matter the result of a betting event and is reliant on the same number of wagers coming in on both sides of a particular betting event.</p>
      <h4>How Wishbone Removes the Juice from Spreads</h4>
      <p className={styles.small}>Let’s take a look at implied probability and how it relates to juice moneyline bets. To calculate the implied probability, all you do is divide the risk and payout of each side and add the percentages together. Here’s an example:</p>
      <img src="/img/ex-juice.png" className={styles.fitImage} />
      <h4>Finding the New Moneyline</h4>
      <p className={styles.small}>Using <a target="_blank" href="https://www.boydsbets.com/money-line-conversion-chart/">this chart</a>, we can find our new line:</p>
      <img src="/img/ex-nojuice.png" className={styles.fitImage} />
      <p className={styles.small}>You’ll notice the payouts have improved for both sides: risk $175 to win $100 on the favorite, and win $175 by risking $100 on the underdog. The same process is applied to spreads that have a (-115) and (-105). That’s why you’ll see -104 and + 104 next to some spreads.</p>
      <p className={styles.small}>This ‘juice’ has likely been a part of any bet you have placed with a bookie over the course of your gambling career, and while we’re sure it hasn’t been a deterrent, wouldn’t you rather keep your money?</p>
    </div>
  )
}

export default Juice
