import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import { IBet, IUser } from 'client/types'
import { settleBet, SettleBetInfo, nudgeLoser, NudgeLoserInfo } from 'client/modules/bets'
import styles from './styles.module.scss'

interface UnsettledBetItem {
  bet: IBet,
  user: IUser,
  settleBet: (betInfo: SettleBetInfo) => any,
  nudgeLoser: (betInfo: NudgeLoserInfo) => any
}

const UnsettledBetItem: React.FC<UnsettledBetItem> = (props) => {
  const { bet, user } = props
  const [didSettle, setDidSettle] = useState(bet.settledUsers.includes(user._id))
  const [didNudgeLoser, setDidNudgeLoser] = useState(bet.didWinnerNudge)
  const isCreator = bet.createdBy._id === user._id
  const displayName = isCreator ? bet.displayName : bet.opposingDisplayName
  const risk = isCreator ? bet.opposingPayout : bet.payout
  const toWin = isCreator ? bet.payout : bet.opposingPayout
  const againstUser = isCreator ? bet.acceptedBy : bet.createdBy
  const isWinner = isCreator ? bet.didCreatorWin : !bet.didCreatorWin
  const endTime = bet.markedEndedAt || bet.lastEventStartTime
  const hasBeenADay = dayjs().diff(endTime, 'hour') >= 24
  const showNudger = isWinner && hasBeenADay

  return (
    <div className={styles.betItem}>
      <div className={styles.betItem__body}>
        <div>{displayName}</div>
        <div>${risk.toFixed(2)}</div>
        <div>${toWin.toFixed(2)}</div>
        <div>
          <span>{againstUser.firstName[0].toUpperCase()}{againstUser.lastName[0].toUpperCase()} </span>
          <span>({againstUser.paymentMethod}: {againstUser.paymentHandle})</span>
        </div>
        <div>{isWinner ? 'Win' : 'Loss'}</div>
      </div>
      <div className={styles.betItem__footer}>
        <button
          className={styles.betItem__settle}
          disabled={didSettle}
          onClick={() => {
            props.settleBet({ betId: props.bet._id })
            setDidSettle(true)
          }}
        >{didSettle ? '✓ Marked as Paid' : 'Mark as Paid'}</button>
        {showNudger &&
          <button
            className={styles.betItem__nudge}
            disabled={didNudgeLoser}
            onClick={async () => {
              props.nudgeLoser({ betId: props.bet._id })
              setDidNudgeLoser(true)
            }}
          >{didNudgeLoser ? '✓ Sent Reminder' : 'Send Friendly Reminder'}</button>
        }
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user
})
const mapDispatchToProps = {
  settleBet,
  nudgeLoser
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnsettledBetItem)
