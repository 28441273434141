import React, { useEffect } from 'react'
import { useParams } from "react-router-dom"
import { connect } from 'react-redux'
import { Field, reduxForm, InjectedFormProps, change } from 'redux-form'
import validator from 'validator'
import { joinUser, JoinInfo, getApplicationByToken } from 'client/modules/auth'
import Toast from 'client/components/Toast'
import FieldComponent from 'client/components/Fields/Field'
import DisabledFieldComponent from 'client/components/Fields/DisabledField'
import EmailFieldComponent from 'client/components/Fields/EmailField'
import PasswordFieldComponent from 'client/components/Fields/PasswordField'
import styles from './styles.module.scss'
import { PropagateLoader } from 'react-spinners'
import { IApplication, IUser } from 'client/types'

const required = (value: any) => (value ? undefined : 'Required')
const isApplicationEmail = (value: any, application: IApplication) => (value === application.email ? undefined : 'Invalid email')

const form = reduxForm({
  form: 'join',
  initialValues: {joinClubToken: ""},
  enableReinitialize: true
})

interface JoinProps {
  errorMessage: string,
  application: IApplication,
  existingUser: IUser,
  joinUser: (_: JoinInfo, token: string) => any,
  getApplicationByToken: (token:string) => any,
  handleSubmit: (_: any) => any
}

const Join: (React.FC<JoinProps & InjectedFormProps>) = ({ errorMessage, application, existingUser, handleSubmit, joinUser, getApplicationByToken }) => {
  let { token } = useParams() as {token: string}
  // const Input = ({ input, meta, ...props }) => <input {...input} {...props} />
  // console.log(token)
  // const [joinClubToken, setJoinClubToken] = useState(token)
  // console.log(joinClubToken)
  // change("join", "joinClubToken", token)
  if(token) {
    useEffect(() => {
      getApplicationByToken(token)
    }, [])
  }

  if(!application) {
    return (
      <div className='skinny-container'>
        <div className='loading-container'>
          <PropagateLoader
            loading={true}
          />
        </div>
      </div>
    )
  }

  if(existingUser) {
    return (
      <div className='skinny-container'>
      <h2>Join</h2>
      <form onSubmit={handleSubmit((joinInfo: JoinInfo) => joinUser(joinInfo, token))}>
        {errorMessage && <Toast text={errorMessage} type='error' />}
        <label htmlFor='firstName'>First Name</label>
        <Field
          id='firstName'
          name='firstName'
          component={FieldComponent}
          validate={[required, (value) => isApplicationEmail(value, application)]}
          defaultValue={existingUser.email}
          type='text'
        />
        <label htmlFor='password'>Password</label>
        <Field
          id='password'
          name='password'
          component={PasswordFieldComponent}
          type='password'
          validate={required}
        />
        <button type='submit' className='ui button fluid large blue'>
          Join
        </button>
      </form>
    </div>
    )
  }

  return (
    <div className='skinny-container'>
      <h2>Join</h2>
      <form onSubmit={handleSubmit((joinInfo: JoinInfo) => joinUser(joinInfo, token))}>
        {errorMessage && <Toast text={errorMessage} type='error' />}
        <label htmlFor='firstName'>First Name</label>
        <Field
          id='firstName'
          name='firstName'
          component={FieldComponent}
          validate={[required]}
          type='text'
        />
        <label htmlFor='lastName'>Last Name</label>
        <Field
          id='lastName'
          name='lastName'
          component={FieldComponent}
          type='text'
          validate={[required]}
        />
        <label htmlFor='paymentMethod'>Payment Method</label>
        <div>
          <Field
            id='paymentMethod'
            name='paymentMethod'
            component='select'
            className={styles.select
          }>
            <option value='Venmo'>Venmo</option>
            <option value='Cash App'>Cash App</option>
            <option value='PayPal'>PayPal</option>
          </Field>
        </div>
        <label htmlFor='paymentHandle'>Payment Username</label>
        <Field
          id='paymentHandle'
          name='paymentHandle'
          component={FieldComponent}
          type='text'
          validate={[required]}
        />
        <label htmlFor='password'>Password</label>
        <Field
          id='password'
          name='password'
          component={PasswordFieldComponent}
          type='password'
          validate={required}
        />
        <button type='submit' className='ui button fluid large blue'>
          Join
        </button>
      </form>
    </div>
  )
}

const mapStateToProps = ({ auth, admin }) => ({
  errorMessage: auth.error,
  message: auth.message,
  application: auth.application,
  existingUser: auth.existingUser
})
const mapDispatchToProps = {
  joinUser,
  getApplicationByToken
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(form(Join))
