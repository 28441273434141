import axios from 'axios'
import { Dispatch, Action } from 'redux'
import { IClub } from 'client/types'
import { API_URL } from 'client/utils/api'
import { AuthTypes } from '../auth'

// Types
export enum ClubTypes {
  CLUB_ERROR = 'club/CLUB_ERROR',
  GET_ALL_CLUBS = 'club/GET_ALL_CLUBS',
  CURRENT_CLUB = 'club/CURRENT_CLUB'
}

interface ClubAction extends Action {
  payload?: any
}

interface ClubError {
  data: any
  status: number
  response: any
}

interface ClubState {
  error?: any
  allClubs?: Array<IClub>
  currentClub?: IClub
}

// Reducer
const INITIAL_STATE: ClubState = {
  error: undefined,
  allClubs: undefined,
  currentClub: undefined
}

export default function (state = INITIAL_STATE, action: ClubAction) {
  switch (action.type) {
    case ClubTypes.CLUB_ERROR:
      return { ...state, error: action.payload }
    case ClubTypes.GET_ALL_CLUBS:
      return {
        ...state,
        allClubs: action.payload
      }
    case ClubTypes.CURRENT_CLUB:
      return { ...state, currentClub: action.payload }
    default:
      return state
  }
}

// Action creator to handle errors
export const errorHandler = (dispatch: Dispatch, error: ClubError, type: ClubTypes) => {
  let errorMessage = ''

  if (!error.data) {
    dispatch({
      type,
      payload: 'An error occurred'
    })
  }

  if (error.data.error) {
    errorMessage = error.data.error
  } else if (error.data) {
    errorMessage = error.data
  }

  dispatch({
    type,
    payload: errorMessage
  })
}

export const getAllClubs = () => {
  return (dispatch: Dispatch) => {
    axios.get(`${API_URL}/clubs`)
    .then((response) => {
      // console.log('ALL CLUBS RESPONSE', response)
      dispatch({
        type: ClubTypes.GET_ALL_CLUBS,
        payload: response.data.clubs
      })

    })
  }
}

export const getClub = (clubId: IClub['_id']) => {
  return (dispatch: Dispatch) => {
    axios.get(`${API_URL}/club/i/${clubId}`)
    .then((response) => {
      dispatch({
        type: ClubTypes.CURRENT_CLUB,
        payload: response.data.club
      })
      dispatch({
        type: AuthTypes.VERIFY_ADMIN,
        payload: response.data.isAdmin
      })
    })
    .catch((error) => {
      errorHandler(dispatch, error.response, ClubTypes.CLUB_ERROR)
    })
  }
}

export const applyToClub = (clubId: IClub['_id'], name: string, email: string ) => {
  // console.log("sending application for " + name + " to club with id: " + clubId)
    const body = {
      clubId: clubId,
      name: name,
      email: email
    }
    // console.log("APPLICATION/CREATE BODY", body)
    return (dispatch: Dispatch) => {
        // console.log("dispatching")
        axios.post(`${API_URL}/application`, {clubId, name, email})
          .then((response) => {
            // console.log("APPLICATION/CREATE RESPONSE", response)
            dispatch({
              type: ClubTypes.CURRENT_CLUB,
              payload: response.data.club
            })
          })
          .catch((error) => {
            console.log("APPLICATION/CREATE ERROR", error)
            errorHandler(dispatch, error.response, ClubTypes.CLUB_ERROR)
          })
      }
}
