import React from 'react'
import { GiSoccerBall, GiBoxingGlove, GiAmericanFootballBall, GiTennisRacket, GiBaseballGlove, GiBasketballBasket, GiMedal } from 'react-icons/gi'
import { IBetOption } from '../../types'

const SportIcon: React.FC<{ sportId: IBetOption['sportId'] }> = (props) => {  
  const icon = (function (color) {
    switch (props.sportId) {
      case '1':  // soccer
        return <GiSoccerBall />
      case '9':  // boxing / UFC
        return <GiBoxingGlove />
      case '12': // football
        return <GiAmericanFootballBall />
      case '13': // tennis
        return <GiTennisRacket />
      case '16': // baseball
        return <GiBaseballGlove />
      case '18': // basketball
        return <GiBasketballBasket />
      default:
        return <GiMedal />
    }
  })(props.sportId)

  return icon
}

export default SportIcon
