import React, { useState } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { resetPassword, ResetPasswordInfo } from 'client/modules/auth'
import styles from './styles.module.scss'

interface VerifyEmailProps {
  resetPassword: (_: ResetPasswordInfo) => any
}

const VerifyEmail: React.FC<VerifyEmailProps> = (props) => {
  const [newPassword, setNewPassword] = useState('')
  const [didReset, setDidReset] = useState(false)
  const token: any = queryString.parse(location.search).token

  return (
    <div className='skinny-container'>
      {didReset &&
        <h3>Your password has been updated!</h3>
      }
      {!didReset &&
        <>
          <h2>Create a New Password</h2>
          <input
            className={styles.input}
            placeholder='New password'
            type='password'
            onChange={(event) => setNewPassword(event.target.value)}
          />
          <button className='ui button fluid large blue' onClick={() => {
            if (newPassword.length <= 4) return
            props.resetPassword({ token, newPassword })
            setDidReset(true)
          }}>Reset Password</button>
        </>
      }
    </div>
  )
}

const mapDispatchToProps = {
  resetPassword
}

export default connect(
  undefined,
  mapDispatchToProps
)(VerifyEmail)
