import axios from 'axios'
import { Dispatch, Action } from 'redux'
import { IChat, IChatGroup, IUser, IClub, ISocketChat } from 'client/types'
import { API_URL, WSS_URL } from 'client/utils/api'

// Types
export enum ChatTypes {
  CHAT_ERROR = 'chat/CHAT_ERROR',
  CURRENT_CHATS = 'chat/CURRENT_CHATS',
  CLUB_CHAT = 'chat/CLUB_CHAT',
  SEND_CHAT = 'chat/SEND_CHAT',
  RECIEVE_CHAT = 'chat/RECIEVE_CHAT'
}

interface ChatAction extends Action {
  payload?: any
}

interface ChatError {
  data: any
  status: number
  response: any
}

interface ChatState {
  error?: ChatError
  chatGroups: Array<IChatGroup>
  clubChatId: IChatGroup['_id']
  websockets: any,
  currentChats: IChat[]
}

// Reducer
const INITIAL_STATE: ChatState = {
  error: undefined,
  chatGroups: undefined,
  clubChatId: undefined,
  currentChats: [],
  websockets: {}
}

export default function (state = INITIAL_STATE, action: ChatAction) {
  switch (action.type) {
    case ChatTypes.CHAT_ERROR:
      return { ...state, error: action.payload }
    case ChatTypes.CURRENT_CHATS:
      const chatGroups = action.payload.chatGroups as Array<IChatGroup>
      const dispatch = action.payload.dispatch as Dispatch
      var newWebsockets = {...state.websockets}
      chatGroups.forEach((chatGroup) => {
        if(!newWebsockets[chatGroup._id]) {
          try {
            const url = `${WSS_URL}/${chatGroup._id}`  // Todo: get app.process
            const socket = new WebSocket(url)
            socket.onopen = function open() {
              // console.log('connected to wss');
            }
            socket.onmessage = function incoming(messageEvent) {
              const chat = JSON.parse(messageEvent.data) as IChat
              dispatch({
                type: ChatTypes.RECIEVE_CHAT,
                payload: chat
              })
            }
            newWebsockets[chatGroup._id] = socket
          } catch (error) {
            console.error(error)
            return { ...state, chatGroups: action.payload }
          }
        }
      })
      const chats = (false ? action.payload.chatGroups[0].chats : [])
      return { ...state, chatGroups: action.payload, websockets: newWebsockets, currentChats: chats}
    case ChatTypes.CLUB_CHAT:
      return { ...state, clubChatId: action.payload }
    case ChatTypes.SEND_CHAT:
      const chat = action.payload as IChat
      const filteredSockets = Object.entries(state.websockets).filter(([chatGroupId, websocket]) =>{
        return chatGroupId === chat.chatGroup
      })
      filteredSockets.forEach(([chatGroupId, websocket])=>{
        const ws = websocket as WebSocket
        const message = JSON.stringify(chat)
        ws.send(message)
      })
      return { ...state, clubChatId: chat.chatGroup }
    case ChatTypes.RECIEVE_CHAT:
      const incomingChat = action.payload as IChat
      const newChats = [...state.currentChats, incomingChat];
      // console.log(newChats)
      return { ...state, currentChats: newChats }
    default:
      return state
  }
}

// Action creator to handle errors
export const errorHandler = (dispatch: Dispatch, error: ChatError, type: ChatTypes) => {
  let errorMessage = ''
  console.log(error.response);

  if (!error.data) {
    dispatch({
      type,
      payload: 'An error occurred'
    })
  }

  if (error.data.error) {
    errorMessage = error.data.error
  } else if (error.data) {
    errorMessage = error.data
  }

  dispatch({
    type,
    payload: errorMessage
  })
}

export const getChatGroups = () => {
  return (dispatch: Dispatch) => {
    axios.get(`${API_URL}/chat`)
      .then((response) => {
        const chatGroups = response.data.chatGroups
        dispatch({
          type: ChatTypes.CURRENT_CHATS,
          payload: {
            chatGroups: chatGroups,
            dispatch: dispatch
          }
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, ChatTypes.CHAT_ERROR)
      })
  }
}

export const getClubChat = (clubId: IClub['_id']) => {
  return (dispatch: Dispatch) => {
    axios.get(`${API_URL}/club/i/${clubId}`)
      .then((response) => {
        dispatch({
          type: ChatTypes.CLUB_CHAT,
          payload: response.data.club.chatGroup
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, ChatTypes.CHAT_ERROR)
      })
  }
}

export const sendChat = (user: IUser, message: string, chatGroupId: IChatGroup['_id']) => {
  return (dispatch: Dispatch) => {
    const chat: IChat = {
      name: user.firstName + " " + user.lastName,
      user: user._id,
      message: message,
      chatGroup: chatGroupId,
      _id: undefined,
      createdAt: undefined
    }
    dispatch({
      type: ChatTypes.SEND_CHAT,
      payload: chat
    })
  }
  
}

export const recieveChat = (rawChat) => {
  const chat = JSON.parse(rawChat) as IChat
  return (dispatch: Dispatch) => {
    dispatch({
      type: ChatTypes.RECIEVE_CHAT,
      payload: {
        chat: chat
      }
    })
  }
}
