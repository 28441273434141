// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".reset_1yy_7i{margin:20px auto 0;display:block;text-align:center;font-size:12px;color:#555}\n", ""]);
// Exports
exports.locals = {
	"reset": "reset_1yy_7i"
};
module.exports = exports;
