export const sportIdToName = {
  All: 'All',
  1: 'Soccer',
  18: 'Basketball',
  // 13: 'Tennis',
  // 91: 'Volleyball',
  // 78: 'Handball',
  16: 'Baseball',
  // 17: 'Ice Hockey',
  // 14: 'Snooker',
  12: 'Football',
  // 3: 'Cricket',
  // 83: 'Futsal',
  // 15: 'Darts',
  // 92: 'Table Tennis',
  // 94: 'Badminton',
  // 8: 'Rugby Union',
  // 19: 'Rugby League',
  // 36: 'Australian Rules',
  // 66: 'Bowls',
  // 9: 'Boxing/UFC'
  // 75: 'Gaelic Sports',
  // 90: 'Floorball',
  // 95: 'Beach Volleyball',
  // 110: 'Water Polo',
  // 107: 'Squash',
  // 151: 'E-sports'
}

export const sportNameToId = (name: string) => Object.keys(sportIdToName).find(key => sportIdToName[key] === name)

export const leagueIdToName = {
  '10037219': 'NFL',
  '10040630': 'NCAAF',
  '10041830': 'NBA',
  '10042997': 'NCAAB',
}

export const leagueIdToSportId = {
  '10037219': '12',
  '10040630': '12',
  '10041830': '18',
  '10042997': '18',
}

export const leagueOptions = [
  {
    sportId: 'All',
    leagueId: 'All',
    name: 'Select League'
  },
  {
    sportId: '12',
    leagueId: '10037219',
    name: 'NFL'
  }, {
    sportId: '12',
    leagueId: '10040630',
    name: 'NCAAF'
  }, {
    sportId: '18',
    leagueId: '10041830',
    name: 'NBA'
  }, {
    sportId: '18',
    leagueId: '10042997',
    name: 'NCAAB'
  }
]
