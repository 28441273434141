import axios from 'axios'
import { Dispatch, Action } from 'redux'
import { IApplication } from 'client/types'
import { API_URL } from 'client/utils/api'

// Types
export enum AdminTypes {
  ADMIN_ERROR = 'admin/ADMIN_ERROR',
  CURRENT_APPLICATIONS = 'admin/CURRENT_APPLICATIONS'
}

interface AdminAction extends Action {
  payload?: any
}

interface AdminError {
  data: any
  status: number
  response: any
}

interface AdminState {
  error?: AdminError
  applications: Array<IApplication>
}

// Reducer
const INITIAL_STATE: AdminState = {
  error: undefined,
  applications: []
}

export default function (state = INITIAL_STATE, action: AdminAction) {
  switch (action.type) {
    case AdminTypes.ADMIN_ERROR:
      return { ...state, error: action.payload }
    case AdminTypes.CURRENT_APPLICATIONS:
      return { ...state, applications: action.payload }
    default:
      return state
  }
}

// Action creator to handle errors
export const errorHandler = (dispatch: Dispatch, error: AdminError, type: AdminTypes) => {
  let errorMessage = ''

  if (!error.data) {
    dispatch({
      type,
      payload: 'An error occurred'
    })
  }

  if (error.data.error) {
    errorMessage = error.data.error
  } else if (error.data) {
    errorMessage = error.data
  }

  dispatch({
    type,
    payload: errorMessage
  })
}

export const getAdminClub = (clubName: string) => {
  clubName = "defaultClub"
  return (dispatch: Dispatch) => {
    axios.get(`${API_URL}/club/${clubName}/applications`)
      .then((response) => {
        dispatch({
          type: AdminTypes.CURRENT_APPLICATIONS,
          payload: response.data.applications
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, AdminTypes.ADMIN_ERROR)
      })
  }
}

export const approveApplication = (clubId: string, applicationId: string) => {
  // console.log("approving in module")
  return (dispatch: Dispatch) => {
    axios.put(`${API_URL}/application/approve`, {club: clubId, application: applicationId})
      .then((response) => {
        dispatch({
          type: AdminTypes.CURRENT_APPLICATIONS,
          payload: response.data.applications
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, AdminTypes.ADMIN_ERROR)
      })
  }
}

export const denyApplication = (clubId: string, applicationId: string) => {
  return (dispatch: Dispatch) => {
    axios.put(`${API_URL}/application/deny`, {club: clubId, application: applicationId})
      .then((response) => {
        dispatch({
          type: AdminTypes.CURRENT_APPLICATIONS,
          payload: response.data.applications
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, AdminTypes.ADMIN_ERROR)
      })
  }
}