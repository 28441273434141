import React from 'react'
import { connect } from 'react-redux'
import { IBet, IUser } from 'client/types'
import UnsettledBet from './UnsettledBet'
import styles from './styles.module.scss'

const UnsettledBetGroup: React.FC<{ bets: Array<IBet>, user: IUser }> = (props) => {
  const totalWinnings = props.bets.reduce((total, bet) => {
    const isCreator = bet.createdBy._id === props.user._id
    const didCreatorWin = bet.didCreatorWin
    if (isCreator && didCreatorWin) {
      return total + bet.payout
    } else if (!isCreator && !didCreatorWin) {
      return total + bet.opposingPayout
    } else {
      return total
    }
  }, 0)

  const totalLosses = props.bets.reduce((total, bet) => {
    const isCreator = bet.createdBy._id === props.user._id
    const didCreatorWin = bet.didCreatorWin
    if (isCreator && !didCreatorWin) {
      return total + bet.opposingPayout
    } else if (!isCreator && didCreatorWin) {
      return total + bet.payout
    } else {
      return total
    }
  }, 0)

  return (
    <div className={styles.betGroup}>
      <div className={styles.betGroup__head}>
        <div>Pick</div>
        <div>Risk</div>
        <div>To Win</div>
        <div>Against</div>
        <div>W/L</div>
      </div>
      <div className={styles.betGroup__body}>
        {props.bets.map(bet =>
          <UnsettledBet bet={bet} key={bet._id} />
        )}
      </div>
      <div className={styles.betGroup__footer}>
        <div>Total Owed</div>
        <div>
          <span>To me:</span>
          <span>${totalWinnings.toFixed(2)}</span>
        </div>
        <div>
          <span>To others:</span>
          <span>${totalLosses.toFixed(2)}</span>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user
})

export default connect(
  mapStateToProps,
)(UnsettledBetGroup)
