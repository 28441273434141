import React from 'react'
import { connect } from 'react-redux'
import { IBet, IUser } from 'client/types'
import styles from './styles.module.scss'

const LockedBetItem: React.FC<{ bet: IBet, user: IUser }> = (props) => {
  const { bet, user } = props
  const isCreator = bet.createdBy._id === user._id
  const displayName = isCreator ? bet.displayName : bet.opposingDisplayName
  // const risk = isCreator ? bet.opposingPayout : bet.payout
  const risk = isCreator ? bet.opposingPayout : bet.payout
  const toWin = isCreator ? bet.payout : bet.opposingPayout
  const againstUser = isCreator ? bet.acceptedBy : bet.createdBy
  const hasStarted = new Date() > new Date(bet.firstEventStartTime)

  return (
    <div className={styles.betItem}>
      <div>{displayName}</div>
      <div>${risk.toFixed(2)}</div>
      <div>${toWin.toFixed(2)}</div>
      <div>
        {againstUser.firstName[0].toUpperCase()}{againstUser.lastName[0].toUpperCase()}
      </div>
      <div>
        <div className={`${styles.status} ${hasStarted ? styles.status__started : ''}`}></div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user
})

export default connect(
  mapStateToProps,
)(LockedBetItem)
