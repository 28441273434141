import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { Link } from 'react-router-dom'
import { loginUser, LoginInfo } from 'client/modules/auth'
import Toast from 'client/components/Toast'
import EmailFieldComponent from 'client/components/Fields/EmailField'
import PasswordFieldComponent from 'client/components/Fields/PasswordField'
import styles from './styles.module.scss'

const required = (value: any) => (value ? undefined : 'Required')

const form = reduxForm({
  form: 'login'
})

interface LoginProps {
  errorMessage: string
  loginUser: (_: LoginInfo) => any
  handleSubmit: (_: any) => any
}

const Login: (React.FC<LoginProps & InjectedFormProps>) = ({ errorMessage, handleSubmit, loginUser }) => {
  return (
    <div className='skinny-container'>
      <h2>Login</h2>
      <form onSubmit={handleSubmit((loginInfo: LoginInfo) => loginUser(loginInfo))}>
        {errorMessage && <Toast text={errorMessage} type='error' />}
        <label htmlFor='email'>Email</label>
        <Field
          id='email'
          name='email'
          component={EmailFieldComponent}
          type='email'
          validate={required}
        />
        <label htmlFor='password'>Password</label>
        <Field
          id='password'
          name='password'
          component={PasswordFieldComponent}
          type='password'
          validate={required}
        />
        <button type='submit' className='ui button fluid large blue'>
          Login
        </button>
      </form>
      <Link to='/reset' className={styles.reset}>Forgot password?</Link>
    </div>
  )
}

const mapStateToProps = ({ auth }) => ({
  errorMessage: auth.error,
  message: auth.message
})
const mapDispatchToProps = {
  loginUser
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(Login))
