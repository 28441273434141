import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { BiBone } from 'react-icons/bi'
import { GiHamburgerMenu } from 'react-icons/gi'
import { Dropdown } from 'semantic-ui-react'
import Notifications from 'client/components/Notifications'
import * as authActions from '../../modules/auth'
import { IClub, IUser } from 'client/types'
import cookies from '../../utils/cookies'
import { API_URL } from 'client/utils/api'

interface MastheadProps {
  user: IUser
  authenticated: boolean
  clubs: Array<IClub>
  setDidRepullUser: (val: boolean) => void
  logoutUser: () => void
  unauthUser: () => void
  authUser: (user: any) => void
  toggleSidebarVisible: () => void
  selectClub: (clubId: string) => void
}

/**
 * Navigation bar. Should handle links in site. Should show
 * different content based on if user is logged in or not
 */
class Masthead extends Component<MastheadProps, {}> {
  async componentDidMount() {
    // If redux says logged in, send request
    try {
      const authInfo = await axios.get(`${API_URL}/me`, {
        headers: { Authorization: cookies.get('token') }
      })
      this.props.setDidRepullUser(true)
      this.props.authUser(authInfo.data.user)
    } catch (error) {
      this.props.setDidRepullUser(true)
      if (error.response.status === 401) {
        this.props.unauthUser()
      }
    }
  }

  handleLogout = () => {
    this.props.logoutUser()
  }

  handleSwitchClub = (clubId: string) => {
    this.props.selectClub(clubId)
  }

  render() {
    const { user, authenticated, clubs } = this.props

    return (
      <div className={'masthead'}>
        <div className='masthead__left'>
          <GiHamburgerMenu
            onClick={() => {
              this.props.toggleSidebarVisible()
              // console.log('clicked')
            }}
            style={{ cursor: 'pointer', fontSize: '20px', marginRight: '25px' }}
          />
          <Link to='/' className='masthead__logo'><BiBone />Wishbone</Link>
        </div>
        <div className='masthead__right'>
          {authenticated && user !== undefined
            ? (
              <>
                <Notifications />
                <Dropdown text={`${user.firstName ? user.firstName : ''}`} pointing className='link item'>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <span onClick={this.handleLogout}>Logout</span>
                    </Dropdown.Item>

                    { clubs &&
                      
                        <Dropdown text="Switch Club" className='link item'>
                          <Dropdown.Menu>
                            {
                              clubs.map((club, index) => 
                                <Dropdown.Item key={club._id}>
                                  <span onClick={() => this.handleSwitchClub(club._id)}>{club.name}</span>
                                </Dropdown.Item>
                              )
                            }
                          </Dropdown.Menu>
                        </Dropdown>
                      
                    }
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : (
              <>
                <Link className='masthead__link' to='/login'>Login</Link>
                {/* <Link className='masthead__link' to='/register'>Register</Link> */}
              </>
            )
          }
        </div>
      </div >
    )
  }
}

const mapStateToProps = ({ auth, club }) => ({
  authenticated: auth.authenticated,
  user: auth.user,
  clubs: club.allClubs
})


export default connect(
  mapStateToProps,
  authActions
)(Masthead)
