// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".betGroup_2Z5pPu{margin-top:20px}.betGroup__head_8TlIAy{font-weight:bold;margin-bottom:10px}.betGroup__footer_UQUnox{margin-top:30px}.betGroup__footer_UQUnox>div:nth-child(1){text-align:right;padding-right:30px !important}.betItem_1p8Upb{padding:10px 0}.betItem_1p8Upb a{text-decoration:none;color:#444;font-size:inherit}.betItem_1p8Upb .status_2jNpHw{margin:1px 0 0 5px}@media screen and (min-width: 768px){.betItem_1p8Upb .status_2jNpHw{margin:4px 0 0 5px}}.betGroup__head_8TlIAy,.betGroup__footer_UQUnox,.betItem_1p8Upb{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;font-size:10px}@media screen and (min-width: 768px){.betGroup__head_8TlIAy,.betGroup__footer_UQUnox,.betItem_1p8Upb{font-size:14px}}.betGroup__head_8TlIAy>div,.betGroup__footer_UQUnox>div,.betItem_1p8Upb>div{-ms-flex-negative:0;flex-shrink:0;-ms-flex-positive:0;flex-grow:0}.betGroup__head_8TlIAy>div:nth-child(1),.betGroup__footer_UQUnox>div:nth-child(1),.betItem_1p8Upb>div:nth-child(1){width:45%;padding-right:10px}.betGroup__head_8TlIAy>div:nth-child(2),.betGroup__footer_UQUnox>div:nth-child(2),.betItem_1p8Upb>div:nth-child(2){width:15%;padding-right:10px}.betGroup__head_8TlIAy>div:nth-child(3),.betGroup__footer_UQUnox>div:nth-child(3),.betItem_1p8Upb>div:nth-child(3){width:15%;padding-right:10px}.betGroup__head_8TlIAy>div:nth-child(4),.betGroup__footer_UQUnox>div:nth-child(4),.betItem_1p8Upb>div:nth-child(4){width:15%}.betGroup__head_8TlIAy>div:nth-child(5),.betGroup__footer_UQUnox>div:nth-child(5),.betItem_1p8Upb>div:nth-child(5){width:10%}.status_2jNpHw{width:10px;height:10px;background:#bbb;border-radius:30px}.status__started_3fDbA7{background:#51c351}\n", ""]);
// Exports
exports.locals = {
	"betGroup": "betGroup_2Z5pPu",
	"betGroup__head": "betGroup__head_8TlIAy",
	"betGroup__footer": "betGroup__footer_UQUnox",
	"betItem": "betItem_1p8Upb",
	"status": "status_2jNpHw",
	"status__started": "status__started_3fDbA7"
};
module.exports = exports;
