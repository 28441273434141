// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".betGroup_HepFgo{margin-top:20px}.betGroup__head_feqVdZ{font-weight:bold;margin-bottom:10px}.betGroup__footer_12pEs6{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center}.betGroup__footer_12pEs6>div:first-of-type{font-weight:bold;margin:30px 0 10px}.betGroup__footer_12pEs6>div:not(:first-of-type){padding:3px 0;width:200px;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between}.betItem_2HGrXo{padding:10px 0;margin-bottom:2px}.betItem__win_1d_LTN{background:#ddecdd}.betItem__loss_1otgBw{background:#eacece}.betGroup__head_feqVdZ,.betItem_2HGrXo{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;font-size:10px}@media screen and (min-width: 768px){.betGroup__head_feqVdZ,.betItem_2HGrXo{font-size:14px}}.betGroup__head_feqVdZ>div,.betItem_2HGrXo>div{-ms-flex-negative:0;flex-shrink:0;-ms-flex-positive:0;flex-grow:0}.betGroup__head_feqVdZ>div:nth-child(1),.betItem_2HGrXo>div:nth-child(1){width:10%;padding-left:5px}.betGroup__head_feqVdZ>div:nth-child(2),.betItem_2HGrXo>div:nth-child(2){width:50%;padding-right:10px}.betGroup__head_feqVdZ>div:nth-child(3),.betItem_2HGrXo>div:nth-child(3){width:15%;padding-right:10px}.betGroup__head_feqVdZ>div:nth-child(4),.betItem_2HGrXo>div:nth-child(4){width:15%}.betGroup__head_feqVdZ>div:nth-child(5),.betItem_2HGrXo>div:nth-child(5){width:10%}.greenText_GdIfwZ{color:#2a8d2a}.redText_398idR{color:#a32f2f}\n", ""]);
// Exports
exports.locals = {
	"betGroup": "betGroup_HepFgo",
	"betGroup__head": "betGroup__head_feqVdZ",
	"betGroup__footer": "betGroup__footer_12pEs6",
	"betItem": "betItem_2HGrXo",
	"betItem__win": "betItem__win_1d_LTN",
	"betItem__loss": "betItem__loss_1otgBw",
	"greenText": "greenText_GdIfwZ",
	"redText": "redText_398idR"
};
module.exports = exports;
