// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_Hb_ZKx{max-width:600px;margin:auto;padding:0 5px 50px}.container_Hb_ZKx .membersList_uvM4mB .header_2oAnOb{display:-ms-grid;display:grid;-ms-grid-columns:50% 50%;grid-template-columns:50% 50%;border-bottom:1px solid black;padding-bottom:10px}.container_Hb_ZKx .membersList_uvM4mB .tableBody_1jMUvp{display:-ms-grid;display:grid;-ms-grid-columns:50% 50%;grid-template-columns:50% 50%}.gridded_3_70T_{display:-ms-grid;display:grid;-ms-grid-columns:50% 50%;grid-template-columns:50% 50%;padding:5px 0}.member_3nJ1BL{padding:5px 0;width:100%;display:-ms-grid;display:grid;-ms-grid-columns:25% 25% 25% 25%;grid-template-columns:25% 25% 25% 25%}\n", ""]);
// Exports
exports.locals = {
	"container": "container_Hb_ZKx",
	"membersList": "membersList_uvM4mB",
	"header": "header_2oAnOb",
	"tableBody": "tableBody_1jMUvp",
	"gridded": "gridded_3_70T_",
	"member": "member_3nJ1BL"
};
module.exports = exports;
