// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".profile_qNudKB{max-width:600px;margin:auto;padding:0px 7px 50px}.profile__head_ULkwIw{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center}.profile__title_1hMhch{font-size:20px;margin-top:15px;font-family:\"Coustard\",monospace,serif}.profile__subtitle_hUc6vw{font-size:16px;margin:0 0 15px;color:#777}.profile__body_3ovjmZ{margin-top:30px}.profile__noBets_1ecGNS{font-size:16px;padding:60px 0 10px;text-align:center;color:#777}.tabber_1RdaQo{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:center;justify-content:center;font-size:16px}.tabber__tab_3DIvtE{padding:10px 10px;cursor:pointer;font-weight:normal;color:#666}.tabber__tab_3DIvtE:first-of-type{margin-right:30px}.tabber__tab_3DIvtE:hover{color:#000}.tabber__tab__selected_3sYrhE{font-weight:bold;color:#000}\n", ""]);
// Exports
exports.locals = {
	"profile": "profile_qNudKB",
	"profile__head": "profile__head_ULkwIw",
	"profile__title": "profile__title_1hMhch",
	"profile__subtitle": "profile__subtitle_hUc6vw",
	"profile__body": "profile__body_3ovjmZ",
	"profile__noBets": "profile__noBets_1ecGNS",
	"tabber": "tabber_1RdaQo",
	"tabber__tab": "tabber__tab_3DIvtE",
	"tabber__tab__selected": "tabber__tab__selected_3sYrhE"
};
module.exports = exports;
